import React from 'react';

const AnceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width="54.625"
      height="39.246"
      viewBox="0 0 54.625 39.246"
    >
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_9444" data-name="Rectangle 9444" width="54.625" height="39.246" fill="#fff" />
        </clipPath>
      </defs>
      <g id="Group_31518" data-name="Group 31518" transform="translate(0 0)">
        <g id="Group_31513" data-name="Group 31513" transform="translate(0 0)" clip-path="url(#clip-path)">
          <path
            id="Path_183885"
            data-name="Path 183885"
            d="M21.37,0A19.625,19.625,0,0,1,38.846,10.691l-1.991.321a17.718,17.718,0,0,0-32.891,5.3l-1.992.323A19.633,19.633,0,0,1,21.37,0M40.821,22.235A19.625,19.625,0,0,1,3.726,28.216l1.98-.315a17.717,17.717,0,0,0,33.136-5.345Z"
            transform="translate(0.485 0)"
            fill="#fff"
            fillRule="evenodd"
          />
          <path
            id="Path_183886"
            data-name="Path 183886"
            d="M0,24.348,5.133,14.317l3.765-.6,3.42,8.649-2.963.477-.72-2.163-4.317.541L2.964,23.871ZM5.233,19.6,8.2,19.414,6.807,16.261Z"
            transform="translate(0 3.372)"
            fill="#fff"
            fillRule="evenodd"
          />
          <path
            id="Path_183887"
            data-name="Path 183887"
            d="M10.632,13.473l-.223,9.236,3.1-.5.136-5.087,2.608,4.646,4.139-.668.179-9.23-3.058.492V17.3l-2.568-4.524Z"
            transform="translate(2.56 2.919)"
            fill="#fff"
            fillRule="evenodd"
          />
          <path
            id="Path_183888"
            data-name="Path 183888"
            d="M28.126,10.624q-.045,1.306-.091,2.615-4.7-.97-5.441,2.836c-.324,2.2,2.724,3,5.127.991q.021,1.411.041,2.817c-6.388,1.851-8.613.273-8.946-2.862.343-4.312,3.871-6.109,9.31-6.4"
            transform="translate(4.628 2.613)"
            fill="#fff"
            fillRule="evenodd"
          />
          <path
            id="Path_183889"
            data-name="Path 183889"
            d="M26.879,10.787l-.232,9.239L34.2,18.808l.047-2.324-4.5.675-.045-1.171,6.2-1.667-.719-.359,2.023-.5-3.522-.224.6.765-4.27.047L29.978,12.7l4.631-.719.174-2.469Z"
            transform="translate(6.554 2.339)"
            fill="#fff"
            fillRule="evenodd"
          />
          <path
            id="Path_183890"
            data-name="Path 183890"
            d="M30,31.667V29.778h.571l.343,1.288.339-1.288h.572v1.889h-.354V30.18l-.375,1.486h-.368l-.374-1.486v1.486Zm2.2,0V29.778h.8a1.378,1.378,0,0,1,.44.051.431.431,0,0,1,.219.181.535.535,0,0,1,.082.3.5.5,0,0,1-.125.351.6.6,0,0,1-.374.174.853.853,0,0,1,.2.158,2.036,2.036,0,0,1,.217.307l.232.369h-.456l-.277-.411a2.605,2.605,0,0,0-.2-.278.305.305,0,0,0-.115-.078.643.643,0,0,0-.192-.021h-.077v.789Zm.381-1.09h.282a1.5,1.5,0,0,0,.343-.022.215.215,0,0,0,.108-.081.251.251,0,0,0,.037-.142.219.219,0,0,0-.051-.153.238.238,0,0,0-.143-.075c-.031,0-.123,0-.278,0h-.3Z"
            transform="translate(7.378 7.323)"
            fill="#fff"
          />
          <path
            id="Path_183891"
            data-name="Path 183891"
            d="M26.726,20.527l7.516-1.164c.28.713.617,2.253.895,2.964A29.741,29.741,0,0,1,48.05,8.764c-5.1,4.745-9.9,9.847-11.273,19q-3.323,1.136-6.643,2.275a10.02,10.02,0,0,0-3.408-9.51"
            transform="translate(6.573 2.155)"
            fill="#fff"
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
};

export default AnceIcon;
