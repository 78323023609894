import React from 'react';

const ArrowIcon = (props: { hexColor?: string; style?: React.CSSProperties }) => {
  const hexColor = props.hexColor || '#ffffff';

  const defaultStyles: React.CSSProperties = {
    transition: '0.2s ease-in-out',
    transform:"rotate(90deg)",
    ...props.style,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.852"
      height="8.108"
      viewBox="0 0 13.852 8.108"
      style={defaultStyles}
    >
      <path
        id="drop_gris"
        data-name="drop gris"
        d="M0,0,6.233,6l6.233-6"
        transform="translate(0.694 0.72)"
        fill="none"
        stroke={hexColor}
        stroke-width="2"
      />
    </svg>
  );
};

export default ArrowIcon;
