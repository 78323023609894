import React from 'react';

const B2CConfirmed = () => {
  return (
    <svg
      id="confirmada"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="17"
      viewBox="0 0 24 17"
      style={{ display: 'inline' }}
    >
      <path
        id="Sustracción_24"
        data-name="Sustracción 24"
        d="M24,17H0V14.875H4A2.069,2.069,0,0,1,2,12.75V2.124A2.068,2.068,0,0,1,4,0H20a2.067,2.067,0,0,1,2,2.124V12.75a2.068,2.068,0,0,1-2,2.126h4V17ZM9.461,6.125,8.047,7.539l3.207,3.207,4.7-4.7L14.539,4.632,11.254,7.917Z"
        fill="#0d455e"
      />
    </svg>
  );
};

export default B2CConfirmed;
