import React from 'react';
interface Icon {
  color?: string
}
const LogoutIcon = ({color}: Icon) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18.832" viewBox="0 0 20 18.832">
      <path
        id="Union_1"
        data-name="Union 1"
        d="M11.929,18.832a1.346,1.346,0,0,1,0-2.691H17.31V2.69H11.929a1.345,1.345,0,1,1,0-2.69h6.726A1.344,1.344,0,0,1,20,1.345V17.486a1.345,1.345,0,0,1-1.345,1.346ZM5.774,15.747.393,10.368a1.348,1.348,0,0,1,0-1.9l5.381-5.38a1.345,1.345,0,1,1,1.9,1.9L4.593,8.071h9.721a1.345,1.345,0,0,1,0,2.69H4.593l3.083,3.085a1.345,1.345,0,1,1-1.9,1.9Z"
        transform="translate(0)"
        fill={color ? color: '#165EEB'}
      />
    </svg>
  );
};

export default LogoutIcon;
