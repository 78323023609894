import React from 'react';

const B2CCancelled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22.608"
      height="25.14"
      viewBox="0 0 22.608 25.14"

      style={{display:"inline"}}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectángulo_9598"
            data-name="Rectángulo 9598"
            width="22.608"
            height="25.14"
            transform="translate(0 0)"
            fill="#f42f43"
          />
        </clipPath>
      </defs>
      <g id="Grupo_33048" data-name="Grupo 33048" transform="translate(0 0.002)">
        <g id="Grupo_31996" data-name="Grupo 31996" transform="translate(0 -0.002)" clip-path="url(#clip-path)">
          <path
            id="Trazado_184357"
            data-name="Trazado 184357"
            d="M375.113.575V1.733a.578.578,0,0,0,.577.578h5.784a.578.578,0,0,0,.578-.577V.576A.578.578,0,0,0,381.475,0h-5.784a.578.578,0,0,0-.578.577"
            transform="translate(-369.33 0.002)"
            fill="#f42f43"
          />
          <path
            id="Trazado_184358"
            data-name="Trazado 184358"
            d="M10.892,92.36H7.518a.578.578,0,1,1,0-1.156h3.707A6.375,6.375,0,0,1,12.8,88.891H7.518a.578.578,0,1,1,0-1.157h6.939a.528.528,0,0,1,.058.006,6.365,6.365,0,0,1,3.989-.425V76.747a1.737,1.737,0,0,0-1.735-1.735H13.878v.578a1.737,1.737,0,0,1-1.735,1.735H6.361A1.737,1.737,0,0,1,4.626,75.59v-.578H1.735A1.737,1.737,0,0,0,0,76.747v18.5a1.737,1.737,0,0,0,1.357,1.692H11.771a6.352,6.352,0,0,1-.878-4.583M7.518,80.795h6.939a.578.578,0,0,1,0,1.157H7.518a.578.578,0,0,1,0-1.157m0,3.47h6.939a.578.578,0,0,1,0,1.156H7.518a.578.578,0,0,1,0-1.156M5.2,92.36H4.048a.578.578,0,1,1,0-1.156H5.2a.578.578,0,1,1,0,1.156m0-3.47H4.048a.578.578,0,1,1,0-1.157H5.2a.578.578,0,1,1,0,1.157m0-3.47H4.048a.578.578,0,0,1,0-1.156H5.2a.578.578,0,0,1,0,1.156m0-3.47H4.048a.578.578,0,1,1,0-1.157H5.2a.578.578,0,1,1,0,1.157"
            transform="translate(0 -73.856)"
            fill="#f42f43"
          />
          <path
            id="Exclusión_1"
            data-name="Exclusión 1"
            d="M5.458,10.917A5.46,5.46,0,0,1,3.334.429,5.46,5.46,0,0,1,7.583,10.488,5.425,5.425,0,0,1,5.458,10.917Zm0-4.629h0L7.4,8.228l.83-.829-1.94-1.94,1.94-1.94L7.4,2.689l-1.94,1.94-1.94-1.94-.83.829,1.94,1.94L2.689,7.4l.83.829,1.94-1.94Z"
            transform="translate(11.692 14.223)"
            fill="#f42f43"
            stroke="rgba(0,0,0,0)"
            stroke-width="1"
          />
        </g>
      </g>
    </svg>
  );
};

export default B2CCancelled;
