import React from 'react';

const KushkiLogoIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="169.951" height="22.83" viewBox="0 0 169.951 22.83">
      <g id="Grupo_31013" data-name="Grupo 31013" transform="translate(-390 -35.683)">
        <g id="LogoKushki.08a6d6fc" transform="translate(465 35.683)">
          <path
            id="Trazado_182719"
            data-name="Trazado 182719"
            d="M68.119,9.3v5.553a4.842,4.842,0,0,1-.353,1.942,4.793,4.793,0,0,1-1.1,1.632,4.543,4.543,0,0,1-1.511,1.029,4.5,4.5,0,0,1-1.788.346,4.787,4.787,0,0,1-3.53-1.421,4.7,4.7,0,0,1-1.426-3.464V8.989a1.556,1.556,0,0,1,.4-1.13,1.322,1.322,0,0,1,.991-.407,1.286,1.286,0,0,1,.515.091,1.3,1.3,0,0,1,.438.289,1.6,1.6,0,0,1,.415,1.157v5.93a2.15,2.15,0,0,0,.606,1.564,2.068,2.068,0,0,0,.71.477,2.046,2.046,0,0,0,.84.147,1.923,1.923,0,0,0,1.371-.55,2.27,2.27,0,0,0,.506-.779,2.292,2.292,0,0,0,.154-.92V8.989a1.57,1.57,0,0,1,.386-1.13,1.3,1.3,0,0,1,.983-.407,1.266,1.266,0,0,1,.7.164,1.288,1.288,0,0,1,.5.523A2.616,2.616,0,0,1,68.119,9.3Z"
            transform="translate(-17.063 -2.135)"
            fill="#1f3664"
          />
          <path
            id="Trazado_182720"
            data-name="Trazado 182720"
            d="M80.986,7.341a5.035,5.035,0,0,1,2.754.726,1.883,1.883,0,0,1,1.037,1.5A1.424,1.424,0,0,1,84.43,10.6a1.155,1.155,0,0,1-.869.363,1.079,1.079,0,0,1-.625-.236c-.217-.157-.424-.319-.627-.489a2.27,2.27,0,0,0-1.358-.58,1.487,1.487,0,0,0-.983.327.956.956,0,0,0-.286.334.969.969,0,0,0-.108.428c0,.55.483.935,1.453,1.177a8.307,8.307,0,0,1,3.2,1.342,3.191,3.191,0,0,1,1.13,2.609,3.686,3.686,0,0,1-.293,1.532,3.644,3.644,0,0,1-.9,1.267,4.279,4.279,0,0,1-3.039,1.113,5.507,5.507,0,0,1-3.286-.954A2.462,2.462,0,0,1,76.6,16.859a1.348,1.348,0,0,1,.367-.943,1.193,1.193,0,0,1,.41-.3,1.18,1.18,0,0,1,.495-.1,2.266,2.266,0,0,1,1.431.707,2.959,2.959,0,0,0,1.844.924,1.461,1.461,0,0,0,1.046-.379,1.286,1.286,0,0,0,.4-.979,1.082,1.082,0,0,0-.736-1,16.868,16.868,0,0,0-1.844-.616,6.464,6.464,0,0,1-2.308-1.1,2.7,2.7,0,0,1-.652-.966,2.732,2.732,0,0,1-.188-1.154,3.334,3.334,0,0,1,.278-1.441,3.3,3.3,0,0,1,.868-1.176A4.357,4.357,0,0,1,80.986,7.341Z"
            transform="translate(-22.275 -2.103)"
            fill="#1f3664"
          />
          <path
            id="Trazado_182721"
            data-name="Trazado 182721"
            d="M95.976,8.987v3.23H100.4V8.987a1.553,1.553,0,0,1,.086-.6,1.526,1.526,0,0,1,.311-.523,1.327,1.327,0,0,1,.453-.309,1.31,1.31,0,0,1,.538-.1,1.295,1.295,0,0,1,.98.407,1.574,1.574,0,0,1,.386,1.13V18.2a1.6,1.6,0,0,1-.085.61,1.575,1.575,0,0,1-.309.531,1.294,1.294,0,0,1-.444.308,1.28,1.28,0,0,1-.529.1,1.31,1.31,0,0,1-.534-.1,1.331,1.331,0,0,1-.449-.312,1.58,1.58,0,0,1-.4-1.144V14.755H95.976v3.439a1.584,1.584,0,0,1-.4,1.144,1.3,1.3,0,0,1-.443.308,1.282,1.282,0,0,1-.529.1,1.314,1.314,0,0,1-.983-.407,1.559,1.559,0,0,1-.4-1.141V8.99a1.545,1.545,0,0,1,.4-1.13,1.324,1.324,0,0,1,.452-.309,1.311,1.311,0,0,1,.536-.1,1.292,1.292,0,0,1,.516.091,1.3,1.3,0,0,1,.44.289,1.6,1.6,0,0,1,.407,1.155Z"
            transform="translate(-27.038 -2.136)"
            fill="#1f3664"
          />
          <path
            id="Trazado_182722"
            data-name="Trazado 182722"
            d="M120.9,10.021,117.5,13.4l3.918,3.659a1.984,1.984,0,0,1,.769,1.322,1.3,1.3,0,0,1-.1.528,1.271,1.271,0,0,1-.3.44,1.348,1.348,0,0,1-.983.374,1.973,1.973,0,0,1-1.249-.671l-4.54-4.338v3.459a1.592,1.592,0,0,1-.4,1.144,1.3,1.3,0,0,1-.443.308,1.282,1.282,0,0,1-.529.1,1.311,1.311,0,0,1-.536-.1,1.329,1.329,0,0,1-.452-.308,1.58,1.58,0,0,1-.4-1.144V8.952a1.624,1.624,0,0,1,.4-1.149,1.308,1.308,0,0,1,.45-.321,1.293,1.293,0,0,1,.541-.1,1.261,1.261,0,0,1,.519.1,1.276,1.276,0,0,1,.435.3,1.677,1.677,0,0,1,.415,1.177v3.189l3.978-4.058a1.947,1.947,0,0,1,1.266-.707,1.312,1.312,0,0,1,.964.4,1.319,1.319,0,0,1,.394.951A1.944,1.944,0,0,1,120.9,10.021Z"
            transform="translate(-32.492 -2.114)"
            fill="#1f3664"
          />
          <path
            id="Trazado_182723"
            data-name="Trazado 182723"
            d="M129.682,18.2V8.99a1.543,1.543,0,0,1,.4-1.13,1.4,1.4,0,0,1,1.945-.027,1.62,1.62,0,0,1,.412,1.157V18.2a1.59,1.59,0,0,1-.391,1.141,1.3,1.3,0,0,1-.446.309,1.281,1.281,0,0,1-.532.1,1.306,1.306,0,0,1-.533-.1,1.32,1.32,0,0,1-.447-.312,1.553,1.553,0,0,1-.316-.525A1.572,1.572,0,0,1,129.682,18.2Z"
            transform="translate(-37.486 -2.136)"
            fill="#1f3664"
          />
          <path
            id="Trazado_182724"
            data-name="Trazado 182724"
            d="M39.183,19.686a1.359,1.359,0,0,1-.527-.105,1.373,1.373,0,0,1-.447-.3,1.392,1.392,0,0,1-.3-.453,1.406,1.406,0,0,1-.1-.534V9a1.4,1.4,0,0,1,.4-.986,1.365,1.365,0,0,1,1.947,0,1.4,1.4,0,0,1,.4.986v9.3a1.4,1.4,0,0,1-.4.986,1.366,1.366,0,0,1-.974.407Z"
            transform="translate(-11.158 -2.179)"
            fill="#1f3664"
          />
          <path
            id="Trazado_182725"
            data-name="Trazado 182725"
            d="M53.166,23.958a1.368,1.368,0,0,1-.956-.39l-9.483-9.259a1.409,1.409,0,0,1-.041-1.966l4.657-4.949a1.367,1.367,0,0,1,1.907,0,1.409,1.409,0,0,1,.089,1.929l-3.707,3.945,8.5,8.3a1.4,1.4,0,0,1-.187,2.16,1.365,1.365,0,0,1-.771.237Z"
            transform="translate(-12.448 -2.007)"
            fill="#00e6b2"
          />
          <path
            id="Trazado_182726"
            data-name="Trazado 182726"
            d="M20.672,4.463l-8.627,9.072a1.238,1.238,0,0,1-1.757.036L5.861,9.3a1.258,1.258,0,0,1-.322-.4,1.273,1.273,0,0,1,.2-1.447,1.246,1.246,0,0,1,.418-.294,1.233,1.233,0,0,1,1,.01,1.249,1.249,0,0,1,.412.3l3.53,3.4,7.851-8.22a11.177,11.177,0,0,0-15.469.984A11.518,11.518,0,0,0,3.6,19.322a11.176,11.176,0,0,0,15.482.748A11.514,11.514,0,0,0,20.672,4.463ZM15.444,16.848h-8.3a1.239,1.239,0,0,1-.881-.37,1.273,1.273,0,0,1,0-1.785,1.239,1.239,0,0,1,.881-.37h8.3a1.239,1.239,0,0,1,.881.37,1.273,1.273,0,0,1,0,1.785A1.239,1.239,0,0,1,15.444,16.848Z"
            transform="translate(-0.454)"
            fill="#00e6b2"
          />
        </g>
        <text
          id="Powered_by"
          data-name="Powered by"
          transform="translate(390 50.097)"
          fill="#002254"
          font-size="9"
          font-family="Helvetica"
        >
          <tspan x="0" y="0">
            POWERED BY
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default KushkiLogoIcon;
