import React from "react";

function MinusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="13"
      viewBox="0 0 12.374 2"
    >
      <g id="Grupo_27584" data-name="Grupo 27584" transform="translate(0 1)">
        <path
          id="Trazado_149"
          data-name="Trazado 149"
          d="M0,0V12.374"
          transform="translate(12.374 0) rotate(90)"
          fill="none"
          stroke="#002554"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export default MinusIcon;
