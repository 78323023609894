import React from 'react';
interface Icon {
  color?: string
}
const LocationIcon = ({color}: Icon) => {
  return (
    <svg id="Location" xmlns="http://www.w3.org/2000/svg" width="16.667" height="20" viewBox="0 0 16.667 20">
      <path
        id="Trazado_183332"
        data-name="Trazado 183332"
        d="M17.333,6A8.343,8.343,0,0,0,9,14.333c0,5.775,7.683,11.329,8.011,11.563a.556.556,0,0,0,.646,0c.327-.234,8.011-5.788,8.011-11.563A8.343,8.343,0,0,0,17.333,6Zm0,11.667a3.333,3.333,0,1,1,3.333-3.333A3.333,3.333,0,0,1,17.333,17.667Z"
        transform="translate(-9 -6)"
        fill={color ? color : "#002554" }
      />
    </svg>
  );
};

export default LocationIcon;
