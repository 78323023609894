import React from 'react';

const CheckCircleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
    <defs>
        <clipPath id="clip-path">
        <rect id="Rectangle_9417" data-name="Rectangle 9417" width="16" height="16" transform="translate(0 -0.12)" fill="#27ae5f"/>
        </clipPath>
    </defs>
    <g id="Check_circle" transform="translate(0 0.12)" clip-path="url(#clip-path)">
        <path id="Path_183867" data-name="Path 183867" d="M9.127,2.5a6.627,6.627,0,1,0,6.627,6.627A6.629,6.629,0,0,0,9.127,2.5Zm0,11.928a5.3,5.3,0,1,1,5.3-5.3A5.308,5.308,0,0,1,9.127,14.428Zm3.416-7.853a.53.53,0,0,0-.751,0L7.8,10.565,6.46,9.228a.53.53,0,0,0-.749,0l-.185.185a.53.53,0,0,0,0,.75l1.9,1.9a.53.53,0,0,0,.75,0l4.553-4.553a.53.53,0,0,0,0-.748Z" transform="translate(-1.175 -1.199)" fill="#27ae5f"/>
    </g>
    </svg>
  )
}

export default CheckCircleIcon;
