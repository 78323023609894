import React from 'react';

const MastercardIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="58"
      height="35"
      viewBox="0 0 58 35"
    >
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectángulo_9521" data-name="Rectángulo 9521" width="58" height="35" rx="2" fill="#fff" />
        </clipPath>
        <clipPath id="clip-path-2">
          <rect id="Rectángulo_9520" data-name="Rectángulo 9520" width="60.32" height="39.813" fill="#fff" />
        </clipPath>
      </defs>
      <g id="mastercard" clip-path="url(#clip-path)">
        <rect id="Rectángulo_9519" data-name="Rectángulo 9519" width="58" height="35" rx="2" fill="#a09b9b" />
        <g id="Grupo_31904" data-name="Grupo 31904" transform="translate(-1.16 -1.823)" clip-path="url(#clip-path-2)">
          <path
            id="Trazado_184085"
            data-name="Trazado 184085"
            d="M-1,1.281A2.836,2.836,0,0,1,1.827-1.563H56.492A2.836,2.836,0,0,1,59.32,1.281V35.406a2.836,2.836,0,0,1-2.828,2.844H1.827A2.836,2.836,0,0,1-1,35.406Z"
            transform="translate(1 1.563)"
            fill="#f4f6f7"
            fillRule="evenodd"
          />
          <path
            id="Trazado_184086"
            data-name="Trazado 184086"
            d="M20.743,27.417A10.427,10.427,0,1,0,10.375,16.99,10.4,10.4,0,0,0,20.743,27.417Z"
            transform="translate(2.82 2.917)"
            fill="#d8232a"
            fillRule="evenodd"
          />
          <path
            id="Trazado_184087"
            data-name="Trazado 184087"
            d="M25,25.033a10.289,10.289,0,0,0,6.6,2.383,10.427,10.427,0,0,0,0-20.854A10.289,10.289,0,0,0,25,8.946a10.466,10.466,0,0,1,0,16.087Z"
            transform="translate(5.16 2.917)"
            fill="#ed9f2d"
            fillRule="evenodd"
          />
          <path
            id="Trazado_184088"
            data-name="Trazado 184088"
            d="M25.52,8.605a10.466,10.466,0,0,0,0,16.087,10.466,10.466,0,0,0,0-16.087Z"
            transform="translate(4.64 3.257)"
            fill="#d8232a"
            fillRule="evenodd"
          />
          <g id="Grupo_31903" data-name="Grupo 31903" transform="translate(24.505 13.271)">
            <path
              id="Trazado_184089"
              data-name="Trazado 184089"
              d="M20.125,9.813h11.31v1.9H20.125Zm0,3.792h11.31v1.9H20.125ZM31.435,17.4H20.125v1.9h11.31Zm-11.31,3.792h11.31v1.9H20.125Zm11.31,3.792H20.125v1.9h11.31Z"
              transform="translate(-20.125 -9.813)"
              fill="#ed9f2d"
              fillRule="evenodd"
            />
          </g>
          <path
            id="Trazado_184090"
            data-name="Trazado 184090"
            d="M-1,.188A1.745,1.745,0,0,1,.74-1.563H34.38A1.745,1.745,0,0,1,36.12.188v21a1.745,1.745,0,0,1-1.74,1.75H.74A1.745,1.745,0,0,1-1,21.188Z"
            transform="translate(1 1.563)"
            fill="rgba(229,229,229,0.01)"
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
};

export default MastercardIcon;
