import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

const passphrase = 'rotoplas-23';

export const encryptWithAES = (text = '') => {
  return AES.encrypt(text, passphrase).toString();
};

export const decryptWithAES = (ciphertext) => {
  const bytes = AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(Utf8);
  return originalText;
};
