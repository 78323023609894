export const findCookie = (cookieToFind) => {
  if (typeof window === "undefined") return
  return document.cookie.split('; ').find((cookieEl) => cookieEl.includes(cookieToFind));
};

export const getCookieValue = (cookie) => {
  if (typeof window === "undefined") return
  return cookie.split('=')[1];
};

export const deleteCookieToken = () => {
  if (typeof window === "undefined") return
  return document.cookie = 'token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};