import React from 'react';

interface Icon {
  color?: string
}

const CompraB2BIcon = ({color}: Icon) => {
  return (
    <svg id="compra_b2b" data-name="compra b2b" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
      <path id="Trazado_183094" data-name="Trazado 183094" d="M11.585,0H3.415a1.172,1.172,0,0,0-.829.343L.343,2.586A1.172,1.172,0,0,0,0,3.415v.9A1.545,1.545,0,0,0,1.545,5.859h0A1.545,1.545,0,0,0,3.091,4.314V3.369A.439.439,0,0,1,3.53,2.93h0a.439.439,0,0,1,.439.439v.945A1.545,1.545,0,0,0,5.515,5.859h0A1.545,1.545,0,0,0,7.061,4.314V3.369A.439.439,0,0,1,7.5,2.93h0a.439.439,0,0,1,.439.439v.945A1.545,1.545,0,0,0,9.485,5.859h0A1.545,1.545,0,0,0,11.03,4.314V3.369a.439.439,0,0,1,.439-.439h0a.439.439,0,0,1,.439.439v.945a1.545,1.545,0,0,0,1.545,1.545h0A1.545,1.545,0,0,0,15,4.314v-.9a1.172,1.172,0,0,0-.343-.829L12.414.343A1.172,1.172,0,0,0,11.585,0Z" fill={color ? color: '#165EEB'}/>
      <path id="Trazado_183095" data-name="Trazado 183095" d="M36.628,231.172v1.758a1.172,1.172,0,0,1-1.172,1.172H28.984a1.172,1.172,0,0,1-1.172-1.172v-1.758A1.172,1.172,0,0,0,26.64,230h0a1.172,1.172,0,0,0-1.172,1.172v5.918a1.172,1.172,0,0,0,1.172,1.172H37.8a1.172,1.172,0,0,0,1.172-1.172v-5.918A1.172,1.172,0,0,0,37.8,230h0A1.172,1.172,0,0,0,36.628,231.172Zm-1.172,5.273H28.984a.586.586,0,0,1-.586-.586h0a.586.586,0,0,1,.586-.586h6.473a.586.586,0,0,1,.586.586h0A.586.586,0,0,1,35.456,236.445Z" transform="translate(-24.722 -223.262)" fill={color ? color: '#165EEB'}/>
    </svg>
  );
};

export default CompraB2BIcon;
