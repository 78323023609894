import React from 'react';

const ArrowDown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  width="21.414" height="12.121" viewBox="0 0 21.414 12.121">
        <path id="drop_azul" data-name="drop azul" d="M0,0,6.233,6l6.233-6" transform="translate(0.694 0.72)" fill="none" stroke="#165EEB" stroke-width="2"/>
    </svg>
  );
};

export default ArrowDown;
