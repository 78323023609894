import React, { CSSProperties } from 'react';

const CalendarioBlue = ({style={}}:{style?:CSSProperties}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.197" height="17.996" viewBox="0 0 16.197 17.996" style={style}>
      <path
        id="icono_calendario"
        data-name="icono calendario"
        d="M6.6,1a.9.9,0,0,0-.9.9v.9H4.8A1.8,1.8,0,0,0,3,4.6V17.2A1.8,1.8,0,0,0,4.8,19H17.4a1.8,1.8,0,0,0,1.8-1.8V4.6a1.8,1.8,0,0,0-1.8-1.8h-.9V1.9a.9.9,0,0,0-1.8,0v.9H7.5V1.9A.9.9,0,0,0,6.6,1ZM4.8,7.3H17.4v9.9H4.8Zm8.1,4.5a.9.9,0,0,0-.9.9v1.8a.9.9,0,0,0,.9.9h1.8a.9.9,0,0,0,.9-.9V12.7a.9.9,0,0,0-.9-.9Z"
        transform="translate(-3 -1)"
        fill="#002554"
      />
    </svg>
  );
};

export default CalendarioBlue;
