import React from 'react';

function PreviusIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
      <g id="Grupo_31940" data-name="Grupo 31940" transform="translate(2749 929) rotate(180)">
        <circle
          id="Elipse_3"
          data-name="Elipse 3"
          cx="15"
          cy="15"
          r="15"
          transform="translate(2719 899)"
          fill="#165eeb"
        />
        <path
          id="Trazado_182364"
          data-name="Trazado 182364"
          d="M0,0,8.8,8.467,17.591,0"
          transform="translate(2731 922.647) rotate(-90)"
          fill="none"
          stroke="#fff"
          stroke-width="2"
        />
      </g>
    </svg>
  );
}

export default PreviusIcon;
