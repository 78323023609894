import React from 'react';

interface Icon {
  color?: string
}
const UserIcon = ({color}: Icon) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="20" viewBox="0 0 16.001 20">
      <path
        id="user"
        d="M16,4a5,5,0,1,0,5,5,5,5,0,0,0-5-5Zm6.011,12H9.99A1.992,1.992,0,0,0,8,17.989v.761a4.6,4.6,0,0,0,2.654,4.031A11.275,11.275,0,0,0,16,24c3.853,0,8-1.643,8-5.25v-.761A1.992,1.992,0,0,0,22.011,16Z"
        transform="translate(-8 -4)"
        fill={color ? color : "#002554" }
      />
    </svg>
  );
};

export default UserIcon;
