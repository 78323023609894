import React from 'react';
import { IIcon } from '../interfaces/IIcon';

const HamburgerMenu = ({ hexColor = '#07367E' }: IIcon): React.ReactElement => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_15207_1088)">
        <path
          d="M3.75 21.25C3.75 21.9404 4.30964 22.5 5 22.5H25C25.6904 22.5 26.25 21.9404 26.25 21.25C26.25 20.5596 25.6904 20 25 20H5C4.30964 20 3.75 20.5596 3.75 21.25ZM3.75 15C3.75 15.6904 4.30964 16.25 5 16.25H25C25.6904 16.25 26.25 15.6904 26.25 15C26.25 14.3096 25.6904 13.75 25 13.75H5C4.30964 13.75 3.75 14.3096 3.75 15ZM5 7.5C4.30964 7.5 3.75 8.05964 3.75 8.75C3.75 9.44036 4.30964 10 5 10H25C25.6904 10 26.25 9.44036 26.25 8.75C26.25 8.05964 25.6904 7.5 25 7.5H5Z"
          fill={hexColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_15207_1088">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HamburgerMenu;
