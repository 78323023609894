import React from 'react';

const CrossCircleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
      <g id="Grupo_30936" data-name="Grupo 30936" transform="translate(-15.004 -80.004)">
        <path
          id="user.281a567b"
          d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Z"
          transform="translate(15.004 80.004)"
          fill="#165eeb"
        />
        <g id="Grupo_30526" data-name="Grupo 30526" transform="translate(23.001 88.001)">
          <path
            id="Trazado_106"
            data-name="Trazado 106"
            d="M2715,20l14,14"
            transform="translate(-2715 -20)"
            fill="none"
            stroke="#fff"
            stroke-width="2"
          />
          <path
            id="Trazado_107"
            data-name="Trazado 107"
            d="M0,0,14,14"
            transform="translate(0 14.002) rotate(-90)"
            fill="none"
            stroke="#fff"
            stroke-width="2"
          />
        </g>
      </g>
    </svg>
  );
};

export default CrossCircleIcon;
