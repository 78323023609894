import { ToastContainer, ToastOptions, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SuccessIcon, ErrorIcon, WarningIcon } from '@/shared/icons';

/*
THE SOFTWARE IS PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY KIND, 
EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF 
MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. 
IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, 
DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT 
OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR 
THE USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

export const crSuccessToastStyles: ToastOptions = {
  position: 'top-right',
  style: {
    backgroundColor: '#D8F8E5',
    border: '1px solid #27AE5F',
    color: 'black',
    marginTop: '63px',
    width: '320px',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '15px',
    right: '85px'
  }
}

export const crErrorToastStyles: ToastOptions = {
  position: 'top-right',
  style: {
    backgroundColor: '#FEE2E5',
    border: '1px solid #F42F43',
    color: 'black',
    marginTop: '63px',
    width: '320px',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '15px',
    right: '85px'
  }
}

export function notifySuccess(props: React.ReactElement | string, configToastObj?: ToastOptions) {
  const configToastObjDefault: ToastOptions = {
    icon: <SuccessIcon />,
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: 'light',
    style: {
      backgroundColor: '#D8F8E5',
      border: '1px solid #27AE5F',
      color: 'black',
      marginTop: '63px',
      width: '320px',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: '15px',
    },
  };

  toast.success(<>{props}</>, { ...configToastObjDefault, ...configToastObj });
}

export function notifyError(props: React.ReactElement | string, configToastObj?: ToastOptions) {
  const configToastObjDefault: ToastOptions = {
    icon: <ErrorIcon />,
    position: 'top-center',
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: 'light',
    style: {
      backgroundColor: '#FEE2E5',
      border: '1px solid #F42F43',
      color: 'black',
      marginTop: '63px',
      width: '320px',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: '15px',
    },
  };
  toast.success(<div>{props}</div>, { ...configToastObjDefault, ...configToastObj });
}

export function Toast() {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
        closeButton={false}
      />
    </>
  );
}
