import React from 'react';

const CloudUploadIcon = () => {
  return (
    <svg
      id="Cloud_Upload"
      data-name="Cloud Upload"
      xmlns="http://www.w3.org/2000/svg"
      width="50.554"
      height="41.745"
      viewBox="0 0 50.554 41.745"
    >
      <path
        id="Trazado_183273"
        data-name="Trazado 183273"
        d="M33.448,29.431a1.4,1.4,0,0,0,1.078-2.3L28.583,20a2.9,2.9,0,0,0-4.316,0l-5.942,7.131a1.405,1.405,0,0,0,2.158,1.8l4.538-5.446v19a1.4,1.4,0,0,0,2.809,0v-19l4.538,5.446A1.4,1.4,0,0,0,33.448,29.431Z"
        transform="translate(-1.15 -2.135)"
        fill="#002554"
      />
      <path
        id="Trazado_183274"
        data-name="Trazado 183274"
        d="M49.11,18.9a15.438,15.438,0,0,0-28.837-3.367A12.637,12.637,0,1,0,18.638,40.7h8.426V36.031A4.213,4.213,0,0,1,21.017,30.2l5.943-7.132a5.705,5.705,0,0,1,8.628,0l5.945,7.134a4.214,4.214,0,0,1-6.045,5.839V40.7h9.83a11.229,11.229,0,0,0,3.79-21.8Z"
        transform="translate(-6 -7)"
        fill="#002554"
      />
    </svg>
  );
};

export default CloudUploadIcon;
