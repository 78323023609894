import React from "react";

const ArrowSidebar = () => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="5.716" height="3.629" viewBox="0 0 5.716 3.629">
      <path id="arrow_side_bar_" data-name="arrow side bar " d="M2.5,3.28-.358.349l.716-.7L2.5,1.848l2.142-2.2.716.7Z" transform="translate(0.358 0.349)" fill="#165EEB"/>
    </svg>
    )
}

export default ArrowSidebar;