import React from 'react';

const ArrowLeftIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g id="Left" transform="translate(224 794) rotate(180)">
        <circle id="Elipse_3" data-name="Elipse 3" cx="8" cy="8" r="8" transform="translate(208 778)" fill="#fff"/>
        <path id="Trazado_182363" data-name="Trazado 182363" d="M0,0,4.136,3.982,8.273,0" transform="translate(214.009 790.136) rotate(-90)" fill="none" stroke="#4f4f4f" stroke-width="1"/>
    </g>
    </svg>
  );
};

export default ArrowLeftIcon;
