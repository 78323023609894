import React from 'react';

const ArrowBackIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6.87" height="11.125" viewBox="0 0 6.87 11.125">
      <path
        id="caret_left.04127433"
        d="M7.238,10.318,2.992,6.062,7.238,1.807,5.931.5.369,6.062l5.562,5.562Z"
        transform="translate(-0.369 -0.5)"
        fill="#165eeb"
      />
    </svg>
  );
};

export default ArrowBackIcon;
