import React from 'react';

interface Icon {
  color?: string
}

const IncidenciaIcon = ({color}: Icon) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14.985" viewBox="0 0 15 14.985">
      <g id="incidencias" transform="translate(-0.001 0.001)">
        <path id="Trazado_184027" data-name="Trazado 184027" d="M13543.292,3546.309a3.6,3.6,0,1,1,2.548,1.056A3.579,3.579,0,0,1,13543.292,3546.309Zm1.828-.625a.721.721,0,1,0,.72-.721A.721.721,0,0,0,13545.12,3545.683Zm0-4.04v2.021a.721.721,0,1,0,1.441,0v-2.021a.721.721,0,1,0-1.441,0Zm-10.677,3.55v-12.815h4.982v4.271h2.848v-4.271h4.984v6.408h-1.425a4.989,4.989,0,0,0-4.982,4.983v1.424Zm2.135-2.135h2.137v-1.425h-2.137Z" transform="translate(-13534.442 -3532.38)" fill={color ? color: '#165EEB'}/>
      </g>
    </svg>
  );
};

export default IncidenciaIcon;
