import React from 'react';
import { IIcon } from '../interfaces/IIcon';

const LogoIcon = ({ width = '99', height = '33.955' }: IIcon) => {
  return (
    <svg
      id="logo"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      // viewBox="0 0 99 33.955"
    >
      <defs>
        {/* <clipPath id="clip-path">
          <rect id="Rectángulo_7" data-name="Rectángulo 7" 
          width="100%" height="100%"
          fill="none" 
          />
        </clipPath> */}
      </defs>
      <g id="Grupo_27477" data-name="Grupo 27477">
        <path
          id="Trazado_14"
          data-name="Trazado 14"
          d="M205.62,86.122l-.049-2.033c-.008-.321-.026-.6-.048-.833l.422-.088.037.538L206,83.7a1.209,1.209,0,0,1,.852-.79.62.62,0,0,1,.78.481,1.212,1.212,0,0,1,.891-.833.675.675,0,0,1,.828.581,3.114,3.114,0,0,1,.055.479l.041,1.694-.466.1-.044-1.655a1.861,1.861,0,0,0-.037-.366c-.064-.289-.212-.456-.522-.39a1.132,1.132,0,0,0-.649,1.2l.034,1.471-.46.1-.039-1.683a1.853,1.853,0,0,0-.037-.343c-.049-.233-.164-.461-.514-.387s-.682.623-.668,1.232l.042,1.439Z"
          transform="translate(-146.736 -58.932)"
          fill="#29a9e1"
        />
        <path
          id="Trazado_15"
          data-name="Trazado 15"
          d="M223.4,80.058c-.036-.165-.064-.434-.093-.725l-.008,0a1.243,1.243,0,0,1-.913,1,.844.844,0,0,1-1-.741,1.971,1.971,0,0,1,1.5-2.38,2.673,2.673,0,0,1,.77-.051l.027,1.5a6.483,6.483,0,0,0,.155,1.3Zm-.208-2.471a1.48,1.48,0,0,0-.342.032,1.5,1.5,0,0,0-.984,1.788c.065.306.242.584.608.509.4-.087.758-.7.738-1.461Zm.211-1.615-.595.977-.374.081.393-.936Z"
          transform="translate(-158.031 -54.241)"
          fill="#29a9e1"
        />
        <path
          id="Trazado_16"
          data-name="Trazado 16"
          d="M232.048,81.127a1.285,1.285,0,0,0,.633.045.488.488,0,0,0,.433-.561c-.047-.217-.174-.328-.5-.427a.843.843,0,0,1-.65-.6.932.932,0,0,1,.822-1.068,1.21,1.21,0,0,1,.608.015l-.055.39a1,1,0,0,0-.508-.026.459.459,0,0,0-.407.521c.045.2.2.3.482.383a.885.885,0,0,1,.677.613.964.964,0,0,1-.866,1.136,1.278,1.278,0,0,1-.732-.049Z"
          transform="translate(-165.604 -56.039)"
          fill="#29a9e1"
        />
        <path
          id="Trazado_17"
          data-name="Trazado 17"
          d="M242.539,75.929l.594,1.423c.132.3.212.5.282.7l.013,0c.037-.191.075-.4.154-.735l.373-1.687.507-.109-.587,2.318a5.243,5.243,0,0,1-.645,1.651,1.831,1.831,0,0,1-.71.653l-.2-.382a1.9,1.9,0,0,0,.455-.378,2.162,2.162,0,0,0,.4-.743.2.2,0,0,0-.008-.13l-1.117-2.476Z"
          transform="translate(-172.811 -53.918)"
          fill="#29a9e1"
        />
        <path
          id="Trazado_18"
          data-name="Trazado 18"
          d="M256,75.455l-.047-2.034c-.009-.319-.026-.595-.048-.83l.42-.088.035.535.019,0a1.2,1.2,0,0,1,.852-.788.62.62,0,0,1,.781.478,1.208,1.208,0,0,1,.888-.832.675.675,0,0,1,.828.583,2.654,2.654,0,0,1,.055.476l.041,1.7-.465.1-.042-1.654a1.922,1.922,0,0,0-.04-.368c-.06-.289-.212-.457-.522-.389a1.134,1.134,0,0,0-.646,1.195L258.141,75l-.461.1-.039-1.685a1.668,1.668,0,0,0-.038-.342c-.049-.233-.163-.459-.514-.386s-.682.622-.667,1.234l.042,1.438Z"
          transform="translate(-182.705 -51.317)"
          fill="#29a9e1"
        />
        <path
          id="Trazado_19"
          data-name="Trazado 19"
          d="M273.833,72.449a1.965,1.965,0,0,1-.87.414,1.028,1.028,0,0,1-1.3-.889,1.745,1.745,0,0,1,1.137-2.091.74.74,0,0,1,.968.535c.158.723-.625,1.091-1.654,1.292a1.193,1.193,0,0,0,.167.513.653.653,0,0,0,.7.237,1.58,1.58,0,0,0,.705-.351Zm-.519-1.922c-.049-.222-.249-.31-.5-.258a1.09,1.09,0,0,0-.721,1.066c.747-.154,1.311-.374,1.222-.8Z"
          transform="translate(-193.93 -49.875)"
          fill="#29a9e1"
        />
        <path
          id="Trazado_20"
          data-name="Trazado 20"
          d="M280.569,65.935l.062,2.857a1.926,1.926,0,0,1-.148,1.008.885.885,0,0,1-.578.4,1.111,1.111,0,0,1-.325.033l-.023-.383a1,1,0,0,0,.223-.022c.241-.051.4-.241.387-.835l-.074-2.962Zm-.244-.442a.279.279,0,0,1-.334-.241.328.328,0,0,1,.246-.386.283.283,0,0,1,.346.239.318.318,0,0,1-.254.386Z"
          transform="translate(-199.594 -46.307)"
          fill="#29a9e1"
        />
        <path
          id="Trazado_21"
          data-name="Trazado 21"
          d="M287.837,67.879a1.687,1.687,0,0,1-1.168,2.056,1.085,1.085,0,0,1-1.331-.9,1.691,1.691,0,0,1,1.165-2.076,1.083,1.083,0,0,1,1.333.923m-2,1.029a.7.7,0,0,0,.819.638c.566-.119.837-.927.7-1.571a.7.7,0,0,0-.816-.632c-.6.126-.843.935-.7,1.565"
          transform="translate(-203.691 -47.783)"
          fill="#29a9e1"
        />
        <path
          id="Trazado_22"
          data-name="Trazado 22"
          d="M296.325,67.868l-.042-1.849c-.009-.374-.039-.749-.068-1.012l.42-.089c.018.2.035.4.057.616l.015,0a1.022,1.022,0,0,1,.707-.845.819.819,0,0,1,.136-.014l.006.485a.6.6,0,0,0-.129.017c-.412.088-.673.661-.655,1.331l.03,1.26Z"
          transform="translate(-211.488 -46.175)"
          fill="#29a9e1"
        />
        <path
          id="Trazado_23"
          data-name="Trazado 23"
          d="M307.963,65.111a7.132,7.132,0,0,1-.089-.726l-.012,0a1.245,1.245,0,0,1-.913,1,.844.844,0,0,1-1-.743,1.972,1.972,0,0,1,1.5-2.382,2.592,2.592,0,0,1,.768-.045l.027,1.492a6.339,6.339,0,0,0,.155,1.3Zm-.208-2.471a1.262,1.262,0,0,0-.344.03,1.505,1.505,0,0,0-.983,1.788c.067.308.24.585.607.508.4-.084.757-.7.74-1.46Z"
          transform="translate(-218.407 -44.415)"
          fill="#29a9e1"
        />
        <path
          id="Trazado_24"
          data-name="Trazado 24"
          d="M316.736,64.019a1.519,1.519,0,0,0,.774.025c.465-.1.783-.416.79-1.194l0-.432-.019,0a1.123,1.123,0,0,1-.838.793.9.9,0,0,1-1.051-.78,1.839,1.839,0,0,1,1.364-2.235,2.357,2.357,0,0,1,.931,0l.067,2.371a2.056,2.056,0,0,1-.3,1.348,1.48,1.48,0,0,1-.906.512,1.71,1.71,0,0,1-.872-.013Zm1.49-3.426a1.136,1.136,0,0,0-.48.007,1.361,1.361,0,0,0-.874,1.65c.064.3.251.637.677.546.406-.086.716-.687.7-1.248Z"
          transform="translate(-225.862 -42.948)"
          fill="#29a9e1"
        />
        <path
          id="Trazado_25"
          data-name="Trazado 25"
          d="M329.5,57.614l.048,2.034c.01.32.027.6.047.831l-.432.093-.037-.536h-.012a1.253,1.253,0,0,1-.856.789.706.706,0,0,1-.9-.642,2.571,2.571,0,0,1-.045-.475l-.038-1.623.475-.1.039,1.6a1.965,1.965,0,0,0,.035.415.424.424,0,0,0,.554.382,1.156,1.156,0,0,0,.682-1.2l-.032-1.472Z"
          transform="translate(-233.66 -41.134)"
          fill="#29a9e1"
        />
        <path
          id="Trazado_26"
          data-name="Trazado 26"
          d="M339.437,58.444a6.643,6.643,0,0,1-.087-.729l-.014.006a1.239,1.239,0,0,1-.911,1,.842.842,0,0,1-1-.742,1.969,1.969,0,0,1,1.5-2.38,2.665,2.665,0,0,1,.771-.051l.025,1.5a6.58,6.58,0,0,0,.155,1.3Zm-.2-2.471a1.266,1.266,0,0,0-.343.032,1.5,1.5,0,0,0-.986,1.788c.066.307.239.585.607.506.4-.084.757-.7.737-1.459Z"
          transform="translate(-240.879 -39.654)"
          fill="#29a9e1"
        />
        <path
          id="Trazado_27"
          data-name="Trazado 27"
          d="M310.612,44.217a1.456,1.456,0,0,1,.684.183,1.285,1.285,0,0,1,.522.509A1.468,1.468,0,0,1,312,45.6a1.379,1.379,0,0,1-.181.685,1.3,1.3,0,0,1-.518.514,1.413,1.413,0,0,1-1.387,0,1.3,1.3,0,0,1-.512-.514,1.366,1.366,0,0,1-.187-.685,1.412,1.412,0,0,1,.187-.694,1.3,1.3,0,0,1,.519-.509,1.465,1.465,0,0,1,.692-.183m0,.234a1.194,1.194,0,0,0-.576.149,1.073,1.073,0,0,0-.432.428,1.128,1.128,0,0,0-.16.575,1.17,1.17,0,0,0,.154.571,1.077,1.077,0,0,0,.435.427,1.146,1.146,0,0,0,.579.154,1.171,1.171,0,0,0,.578-.154,1.086,1.086,0,0,0,.426-.427,1.143,1.143,0,0,0,0-1.146,1.071,1.071,0,0,0-.439-.428,1.152,1.152,0,0,0-.564-.149M310,46.368V44.88h.517a1.339,1.339,0,0,1,.379.039.35.35,0,0,1,.188.146.364.364,0,0,1,.073.217.392.392,0,0,1-.12.286.457.457,0,0,1-.311.134.456.456,0,0,1,.128.08,1.678,1.678,0,0,1,.221.294l.182.291h-.293l-.135-.235a1.257,1.257,0,0,0-.25-.342.316.316,0,0,0-.2-.056h-.141v.634Zm.24-.838h.292a.474.474,0,0,0,.29-.063.2.2,0,0,0,.076-.167.209.209,0,0,0-.035-.116.213.213,0,0,0-.1-.077.747.747,0,0,0-.243-.026h-.278Z"
          transform="translate(-220.768 -31.569)"
        />
        <path
          id="Trazado_28"
          data-name="Trazado 28"
          d="M246.649,32.425V28.6a4,4,0,0,0-.667-2.46A2.963,2.963,0,0,0,244.344,25a5.055,5.055,0,0,0-2.286.024,10.649,10.649,0,0,0-3.742,1.7v3.03a6.726,6.726,0,0,1,2.738-1.755,2.7,2.7,0,0,1,1.6-.134c.492.235.473.6.483,1.049q-5.956,2-5.955,5.423a2.827,2.827,0,0,0,1.057,2.357,2.77,2.77,0,0,0,2.4.561,3.284,3.284,0,0,0,1.316-.614,5.8,5.8,0,0,0,1.187-1.257c.009,1.05.554.976.806.918s3.4-.709,3.4-.709c-.451-.171-.807-.624-.694-3.175m-3.515-.155a2.675,2.675,0,0,1-.305,1.307,1.282,1.282,0,0,1-.889.676.926.926,0,0,1-.859-.225,1.189,1.189,0,0,1-.365-.928q0-1.572,2.418-2.3Z"
          transform="translate(-169.338 -17.767)"
        />
        <path
          id="Trazado_29"
          data-name="Trazado 29"
          d="M122.463,46.775l-1.734.362V51.8c0,.718.287,1.014.866.892a1.834,1.834,0,0,0,.868-.5v3.088a7.654,7.654,0,0,1-2.163.823,3.157,3.157,0,0,1-2.626-.3,2.974,2.974,0,0,1-.838-2.436V47.949l-1.4.294v-2.8s1.154-.243,1.408-.292c-.151-2.725.587-2.95,1.405-3.137l2.311-.435v2.794l1.9-.393Z"
          transform="translate(-82.417 -29.682)"
        />
        <path
          id="Trazado_30"
          data-name="Trazado 30"
          d="M222.759,17.8c-.359.1-1.723.643-1.765,3.039V33.283l2.084-.437c.363-.1,1.726-.644,1.763-3.039V17.367Z"
          transform="translate(-157.782 -12.399)"
        />
        <path
          id="Trazado_31"
          data-name="Trazado 31"
          d="M190.914,36.865a2.658,2.658,0,0,0-2.908-1.108,3.334,3.334,0,0,0-1.425.679,4.68,4.68,0,0,0-1.021,1.18c-.008-.534.067-1.063-.52-.986-.607.125-3.46.732-3.46.732a.9.9,0,0,1,.186.506c.019.325-.012,16.173-.012,16.173l2.347-.493c.841-.228,1.5-.355,1.5-2.274l0-4a3.207,3.207,0,0,0,1.028.469,2.635,2.635,0,0,0,1.131,0,4.109,4.109,0,0,0,2.172-1.281,7.437,7.437,0,0,0,1.514-2.523A9.3,9.3,0,0,0,192,40.716a6.435,6.435,0,0,0-1.084-3.851m-3.221,6.905a1.555,1.555,0,0,1-1.071.961,1,1,0,0,1-.579-.041,1.277,1.277,0,0,1-.483-.37V41.556a3.455,3.455,0,0,1,.3-1.6,1.24,1.24,0,0,1,.881-.692.865.865,0,0,1,.969.489,3.79,3.79,0,0,1,.37,1.916,4.9,4.9,0,0,1-.386,2.1"
          transform="translate(-129.642 -25.473)"
        />
        <path
          id="Trazado_32"
          data-name="Trazado 32"
          d="M281.82,27.857a6.325,6.325,0,0,1-3.422,1.854,7.84,7.84,0,0,1-3.771-.212V26.024a4.491,4.491,0,0,0,3.182.843,1.856,1.856,0,0,0,.889-.454,1.054,1.054,0,0,0,.366-.79.556.556,0,0,0-.293-.508,3.828,3.828,0,0,0-1.093-.313,3.926,3.926,0,0,1-2.487-1.1,2.663,2.663,0,0,1-.565-1.617,3.817,3.817,0,0,1,1.322-2.837,6.539,6.539,0,0,1,3.206-1.637,7.1,7.1,0,0,1,3.24.071v3.389a3.39,3.39,0,0,0-2.558-.615,1.875,1.875,0,0,0-.826.357.746.746,0,0,0-.273.6c0,.34.4.55,1.186.64,2.148.259,3.229,1.172,3.229,2.734a4.387,4.387,0,0,1-1.333,3.062"
          transform="translate(-196.074 -12.467)"
        />
        <path
          id="Trazado_33"
          data-name="Trazado 33"
          d="M86.872,58.566a4.411,4.411,0,0,0-3.89-.745A6.214,6.214,0,0,0,79.128,60.3a7.635,7.635,0,0,0-1.464,4.659,5.3,5.3,0,0,0,1.4,3.949,4.07,4.07,0,0,0,3.905.846,6.309,6.309,0,0,0,3.966-2.511A7.589,7.589,0,0,0,88.4,62.605a4.912,4.912,0,0,0-1.524-4.039m-2.8,7.247A1.506,1.506,0,0,1,83,66.785a.889.889,0,0,1-1.06-.53,4.958,4.958,0,0,1-.354-2.182,6.186,6.186,0,0,1,.356-2.355,1.516,1.516,0,0,1,1.073-.984.9.9,0,0,1,1.064.543,5.1,5.1,0,0,1,.352,2.214,6.069,6.069,0,0,1-.352,2.322"
          transform="translate(-55.449 -41.18)"
        />
        <path
          id="Trazado_34"
          data-name="Trazado 34"
          d="M151.149,45.426a4.429,4.429,0,0,0-3.887-.742,6.219,6.219,0,0,0-3.857,2.482,7.636,7.636,0,0,0-1.463,4.661,5.286,5.286,0,0,0,1.4,3.945,4.075,4.075,0,0,0,3.905.852,6.386,6.386,0,0,0,3.967-2.512,7.661,7.661,0,0,0,1.459-4.647,4.908,4.908,0,0,0-1.524-4.04m-2.794,7.251a1.5,1.5,0,0,1-1.077.972.9.9,0,0,1-1.063-.532,4.934,4.934,0,0,1-.354-2.179,6.026,6.026,0,0,1,.36-2.356,1.505,1.505,0,0,1,1.068-.987.906.906,0,0,1,1.067.539,5.179,5.179,0,0,1,.351,2.22,6.116,6.116,0,0,1-.351,2.322"
          transform="translate(-101.342 -31.802)"
        />
        <path
          id="Trazado_35"
          data-name="Trazado 35"
          d="M49.88,61.972a2.592,2.592,0,0,0-1.613-2.021A4.618,4.618,0,0,0,50.838,55.5a3.655,3.655,0,0,0-1.319-2.942c-.776-.491-1.685-.582-3.383-.186,0,0-4.4.944-5.96,1.209.32.039.42.234.424.6s0,14.5,0,14.5l2.1-.4c.978-.229,1.723-.566,1.757-2.213l-.015-4.435.349-.071a1.205,1.205,0,0,1,1.579.97c.175.58.429,2.348.608,3.487a1.161,1.161,0,0,0,1.53,1.085c.587-.1,2.823-.556,3.029-.609-1.021-.25-1.376-3.231-1.653-4.524m-3.321-4.021a2.1,2.1,0,0,1-1.3.757l-.794.161.019-3.285.967-.19a1.4,1.4,0,0,1,1.182.143,1.253,1.253,0,0,1,.383,1.066,2.146,2.146,0,0,1-.453,1.347"
          transform="translate(-28.684 -37.213)"
        />
        <path
          id="Trazado_36"
          data-name="Trazado 36"
          d="M22.96,30.32c-.009,0-.011-.007-.018-.017s-.015-.014-.022-.018a2.913,2.913,0,0,0-1.716-.647,9,9,0,0,0-2,.035C8.924,30.618,0,39.611,0,50.414a44.334,44.334,0,0,0,.406,4.673L.66,55.03c-.071-.656-.179-2.114-.179-2.79A18.731,18.731,0,0,1,19.075,33.62c.691,0,2.073.1,2.545.088h.024a1.975,1.975,0,0,0,1.925-1.966,1.945,1.945,0,0,0-.6-1.409l-.011-.013"
          transform="translate(0 -21.132)"
          fill="#90c7e6"
        />
        <path
          id="Trazado_37"
          data-name="Trazado 37"
          d="M28.829,12.895a10,10,0,0,0-2.587-.307A24.262,24.262,0,0,0,7.768,20.971,22.692,22.692,0,0,0,3.815,27.8l.411-.1a19.963,19.963,0,0,1,4.011-5.44,21,21,0,0,1,14.906-5.816,27.316,27.316,0,0,1,3,.26c.824.11,1.579.242,1.964.267h.128a2.081,2.081,0,0,0,.6-4.08"
          transform="translate(-2.724 -8.987)"
          fill="#29a9e1"
        />
        <path
          id="Trazado_38"
          data-name="Trazado 38"
          d="M29.294,3.85c1.2-.334,3.014-.573,3.967-.757A1.551,1.551,0,0,0,33.142.011a7.362,7.362,0,0,0-2.136.274A15.157,15.157,0,0,0,27.1,1.832a17.855,17.855,0,0,0-6.961,7.348l.4-.021A18.645,18.645,0,0,1,29.294,3.85"
          transform="translate(-14.375 0)"
          fill="#0176bc"
        />
      </g>
    </svg>
  );
};

export default LogoIcon;