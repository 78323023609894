import React, { ButtonHTMLAttributes, MouseEventHandler, ReactNode } from 'react';
import styles from './styles.module.scss';

const Button = (props: {
  icon?: ReactNode
  id?:string
  variant?: string;
  form?:string,
  children: React.FunctionComponent | string | React.ReactNode;
  type?: 'button' | 'submit' | 'reset' | undefined;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties;
  disabled?: boolean;
}) => {

  const variant = props.variant || 'primary';
  const type = props.type || 'button';

  const defaultStyles: React.CSSProperties = {
    fontWeight: 'bold',
    fontSize:"14px"
  };
  return (
    <button
      className={styles[variant]}
      id={props.id}
      form={props.form}
      type={type}
      onClick={props.onClick}
      style={props?.disabled === true ? { opacity: '0.4',  ...defaultStyles, ...props.style} : { ...defaultStyles, ...props.style }}
      disabled={props?.disabled === undefined ? false : props?.disabled}
    >
      {props.children}
      {props.icon && <div>{props.icon}</div>}
    </button>
  );
};

export default Button;
