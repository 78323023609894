import React from 'react';

const DownloadIcon = ({ 
  color = "#165eeb"
 } : {
  color?: string
 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18.223" viewBox="0 0 19 18.223">
      <g id="Grupo_31023" data-name="Grupo 31023" transform="translate(267.961 -20.563) rotate(90)">
        <path
          id="Trazado_183568"
          data-name="Trazado 183568"
          d="M28.932,240h7v17h-7"
          transform="translate(1.854 9.961)"
          fill="none"
          stroke={ color }
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <g id="Grupo_30941" data-name="Grupo 30941" transform="translate(34.094 263.947) rotate(180)">
          <path
            id="Trazado_183569"
            data-name="Trazado 183569"
            d="M6,0,0,5.5,6,11"
            transform="translate(0.531 0.486)"
            fill="none"
            stroke={ color }
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Trazado_183570"
            data-name="Trazado 183570"
            d="M0,0H12"
            transform="translate(0.531 5.986)"
            fill="none"
            stroke={ color }
            stroke-linecap="round"
            stroke-width="2"
          />
        </g>
      </g>
    </svg>
  );
};

export default DownloadIcon;
