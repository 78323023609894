import React from 'react';

const AmexIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="58" height="35" viewBox="0 0 58 35">
      <g id="amex" transform="translate(-1839 -2275)">
        <rect
          id="Rectángulo_9518"
          data-name="Rectángulo 9518"
          width="58"
          height="35"
          rx="2"
          transform="translate(1839 2275)"
          fill="#016fd0"
        />
        <g id="Grupo_31901" data-name="Grupo 31901" transform="translate(1842.491 2285.878)">
          <g id="Grupo_31902" data-name="Grupo 31902" transform="translate(0 0)">
            <path
              id="Trazado_184082"
              data-name="Trazado 184082"
              d="M51,13.229H46.427c-1.058,0-2.118-.031-3.175.013a1.23,1.23,0,0,1-1.1-.512c-.4-.484-.833-.937-1.309-1.468-.512.546-.981,1.091-1.5,1.58a1.31,1.31,0,0,1-.8.365c-9.291.021-28.253.03-28.253.03L9.51,11.242H7.494l-.929,1.994H0s.818-2.027,1.222-2.979C2.58,7.056,3.955,3.861,5.3.651A.869.869,0,0,1,6.238,0c1.513.009,3.026.014,4.8.016l1.386,3.191V.022c1.755,0,3.694,0,5.632-.013a.849.849,0,0,1,.928.6c.338.834.736,1.643,1.174,2.605.4-.949.778-1.76,1.094-2.592.085-.224.3-.619.3-.619h.6C27.64.019,33.129,0,38.617.028a1.473,1.473,0,0,1,.9.4c.5.465.926,1,1.444,1.583C41.49,1.449,41.98.9,42.509.381A1.152,1.152,0,0,1,43.2.029C45.685,0,48.174.015,50.664.019a2.445,2.445,0,0,1,.352.075L45.059,6.66,51,13.229M20.264,8.048c-.142-.245-.239-.385-.31-.538-.764-1.66-1.539-3.315-2.276-4.988a.77.77,0,0,0-.839-.55c-.783.03-1.569.042-2.351,0-.542-.03-.718.151-.71.695.027,1.734.011,3.469.012,5.2,0,.715-.012,2.471-.012,2.471S11.4,4.923,10.171,1.973c-1.31.027-3.288,0-3.288,0C5.861,4.43,3.369,10.231,2.938,11.245l2.474,0,.962-1.991h4.1l1.106,1.991s2.767.005,3.9,0c.169,0,.338-.03.58-.053V4.7a.947.947,0,0,1,.384.466q1.073,2.425,2.143,4.852c.562,1.269.563,1.253,1.946,1.25a.729.729,0,0,0,.786-.522c.652-1.56,1.339-3.1,2.02-4.651.205-.466.434-.922.652-1.383l.163.042v6.456h2.33c0-2.983,0-5.916,0-8.85,0-.335-.174-.384-.447-.381-.9.008-1.8.019-2.7-.006a.627.627,0,0,0-.7.465c-.441,1.114-.919,2.214-1.388,3.318-.312.736-.633,1.468-.989,2.293M40.891,4.98c-.8-.888-1.522-1.737-2.3-2.538A1.42,1.42,0,0,0,37.712,2c-2.919-.031-5.838,0-8.756-.024-.525,0-.657.159-.652.672.025,2.679.012,5.359.015,8.039,0,.17.022.341.036.529a3.788,3.788,0,0,0,.379.041c3,0,5.995.008,8.992-.011a1.014,1.014,0,0,0,.641-.3c.832-.852,1.636-1.732,2.5-2.655.8.9,1.548,1.754,2.314,2.589a.986.986,0,0,0,.564.354c.868.038,1.738.017,2.728.017L42.317,6.661l4.232-4.68c-.818,0-1.5.036-2.184-.012a1.316,1.316,0,0,0-1.169.511c-.728.823-1.488,1.617-2.3,2.5"
              transform="translate(0.003 0)"
              fill="#fff"
            />
            <path
              id="Trazado_184083"
              data-name="Trazado 184083"
              d="M60.434,36.828l1.3-3.186h.153L63.2,36.828Z"
              transform="translate(-53.324 -29.663)"
              fill="#fff"
            />
            <path
              id="Trazado_184084"
              data-name="Trazado 184084"
              d="M260.1,23.609h5.01c.024-.469.045-.894.067-1.318l.123-.1,3.543,3.985-3.641,3.973-.084-1.3H262.6c-.666,0-1.331-.006-2,0-.336,0-.495-.126-.49-.49.019-1.515-.134-1.195,1.141-1.217,1.076-.019,2.154-.024,3.23.006.428.012.58-.095.579-.552,0-1.428.015-1.428-1.407-1.428-.959,0-1.919-.026-2.877.008-.548.02-.744-.184-.676-.718a7.372,7.372,0,0,0,.005-.848"
              transform="translate(-229.495 -19.571)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AmexIcon;
