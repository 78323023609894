import React from 'react';

const OpenEyeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.124" height="9.63" viewBox="0 0 14.124 9.63">
      <path
        id="visibility_FILL1_wght400_GRAD0_opsz24_1_"
        data-name="visibility_FILL1_wght400_GRAD0_opsz24 (1)"
        d="M47.062-792.3a2.786,2.786,0,0,0,2.046-.843,2.786,2.786,0,0,0,.843-2.046,2.786,2.786,0,0,0-.843-2.046,2.786,2.786,0,0,0-2.046-.843,2.786,2.786,0,0,0-2.046.843,2.786,2.786,0,0,0-.843,2.046,2.786,2.786,0,0,0,.843,2.046A2.786,2.786,0,0,0,47.062-792.3Zm0-1.156a1.672,1.672,0,0,1-1.228-.506,1.672,1.672,0,0,1-.506-1.228,1.672,1.672,0,0,1,.506-1.228,1.672,1.672,0,0,1,1.228-.506,1.672,1.672,0,0,1,1.228.506,1.672,1.672,0,0,1,.506,1.228,1.672,1.672,0,0,1-.506,1.228A1.672,1.672,0,0,1,47.062-793.452Zm0,3.082a7.433,7.433,0,0,1-4.269-1.308A7.436,7.436,0,0,1,40-795.185a7.436,7.436,0,0,1,2.793-3.507A7.433,7.433,0,0,1,47.062-800a7.433,7.433,0,0,1,4.269,1.308,7.436,7.436,0,0,1,2.793,3.507,7.436,7.436,0,0,1-2.793,3.507A7.433,7.433,0,0,1,47.062-790.37Z"
        transform="translate(-40 800)"
        fill="#000255"
      />
    </svg>
  );
};

export default OpenEyeIcon;
