import React from 'react';

const SearchIcon = (props:{className?:string}) => {
  return (
    <svg className={props.className} id="Buscar" xmlns="http://www.w3.org/2000/svg" width="15.314" height="14.552" viewBox="0 0 15.314 14.552">
      <path
        id="Trazado_79"
        data-name="Trazado 79"
        d="M9.366,2.623A5.727,5.727,0,0,0,3.116,7.9a5.727,5.727,0,0,0,5.266,6.258,5.727,5.727,0,0,0,6.25-5.276A5.727,5.727,0,0,0,9.366,2.623ZM8.481,13a4.63,4.63,0,1,1,5-4.221A4.64,4.64,0,0,1,8.481,13Z"
        transform="translate(-3.094 -2.601)"
        fill="#165EEB"
      />
      <path
        id="Trazado_80"
        data-name="Trazado 80"
        d="M19.6,17.725l-.385.432-.385.432,4.311,3.853.385-.432.385-.432Z"
        transform="translate(-8.596 -7.889)"
        fill="#165EEB"
      />
    </svg>
  );
};

export default SearchIcon;
