import axios from 'axios';
import CONST from '@/shared/consts/CONST_PROJECT.json';
import { ICredit, IEstadoCuenta } from '../../rotoplasCredit/RotoplasCredit';

export interface ILimiteCredito {
    msjBloqueo?: string;
    codeBloqueo?: number;
    claseRiesgo?: string;
    comprometido?: string;
    totalValores?: string;
    totalCreditos?: string;
    gradoAgotamiento?: string;
    comprometidoE?: string;
    limite?: string;
    moneda?: string;
    statusCode?: number;
}

const typeBloqueo = {
    "0": 'no bloqueado',
    "1": 'cliente bloqueado (central)',
    "2": 'Cliente bloqueado a nivel área de control de crédito',
    "3": 'cliente bloqueado por límite de crédito',
    "4": 'facturas vencidas',
};


export const limitCredit = async ({ customerExternalId }: { customerExternalId: string }) => {
    if (customerExternalId == '') return { statusCode: 400 };
    const req = await axios.post(
        `${CONST.API_ENDPOINT}/sap/LimiteDeCredito`,
        {
            IV_KUNNR: `${customerExternalId}`,
        },
        {
            auth: {
                username: 'Thinkcares',
                password: 'g#w5M!zo8*8k~oJ]]K2U',
            },
            headers: {
                'Content-Type': 'application/json',
            },
            validateStatus: function(status) {
                return status < 501; // Resolve only if the status code is less than 500
            },
        },
    );
    if (req.status >= 300) return { statusCode: req.status } as ILimiteCredito;
    const limite: ILimiteCredito = {
        //@ts-ignore
        msjBloqueo: typeBloqueo[req.data.ev_crblb == "" ? 0 : req.data.ev_crblb],
        codeBloqueo: req.data.ev_crblb == "" ? 0 : req.data.ev_crblb,
        claseRiesgo: req.data.ev_ctlpc,
        comprometido: req.data.ev_oblig,
        totalValores: req.data.ev_sauft,
        totalCreditos: req.data.ev_skfor,
        comprometidoE: req.data.ev_ssobl,
        moneda: req.data.ev_waers,
        limite: String(Number(req?.data?.ev_klimk ?? 0) - Number(req?.data?.ev_sauft ?? 0)),
        statusCode: 200,
    };

    return limite;
};

export const creditRotoplas = async ({ customerExternalId }: { customerExternalId: string }): Promise<IEstadoCuenta> => {
    if (!customerExternalId) return { statusCode: 400 } as IEstadoCuenta
    const req = await axios.post(
        `${CONST.API_ENDPOINT}/sap/EstadoDeCuenta`,
        {
            IV_CUST_NUM: `${customerExternalId}`,
        },
        {
            auth: {
                username: "Thinkcares",
                password: "g#w5M!zo8*8k~oJ]]K2U",
            },
            headers: {
                'Content-Type': 'application/json',
            },
            validateStatus: function(status) {
                return status < 501; // Resolve only if the status code is less than 500
            },
        }
    );
    if (req.status >= 300) return { statusCode: req.status } as IEstadoCuenta
    const credit: any = {
        credito: {
            avencer_value: req.data.es_edocta_hdr.avencer_value,
            corte_date: req.data.es_edocta_hdr.corte_date,
            credit_available: req.data.es_edocta_hdr.credit_available,
            credit_limit: req.data.es_edocta_hdr.credit_limit,
            sales_value: req.data.es_edocta_hdr.sales_value,
            vencido_value: req.data.es_edocta_hdr.vencido_value,
            total_value: req.data.es_edocta_hdr.total_value,
        },
        customer: req.data.es_edocta_hdr.customer,
        movimientos: req.data.et_edocta_det,
    };
    return credit
}

export const downloadCfdi = async ({ UUID }: { UUID: string }) => {
    if (UUID == '') return;
    const req = await axios.post(
        `${CONST.API_ENDPOINT}/sap/Cfdi`,
        {
            uuid: `${UUID}`,
        },
        {
            auth: {
                username: 'Thinkcares',
                password: 'g#w5M!zo8*8k~oJ]]K2U',
            },
            headers: {
                'Content-Type': 'application/json',
            },
            validateStatus: function(status) {
                return status < 501; // Resolve only if the status code is less than 500
            },
        },
    );

    return req
}
