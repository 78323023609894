import React from 'react';
import { IIcon } from '../interfaces/IIcon';

const TransferIcon = ({ height = '17.753', width = '27.867' }: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 27.867 17.753"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_9018"
            data-name="Rectangle 9018"
            width={width}
            height={height}
            transform="translate(0 0)"
            fill="#165EEB"
          />
        </clipPath>
      </defs>
      <g id="Group_30272" data-name="Group 30272" transform="translate(0 0)">
        <g id="Group_30271" data-name="Group 30271" clip-path="url(#clip-path)">
          <path
            id="Path_183635"
            data-name="Path 183635"
            d="M19.939,5.605V4.528a.4.4,0,0,0-.313-.394L14,2.941a.387.387,0,0,0-.159,0L8.24,4.133a.4.4,0,0,0-.313.394V5.6A.4.4,0,0,0,8.32,6h.127v7.483H8.326a.4.4,0,0,0-.393.4v.537a.4.4,0,0,0,.393.4H19.541a.4.4,0,0,0,.393-.4v-.537a.4.4,0,0,0-.393-.4H19.42V6h.126v0a.4.4,0,0,0,.393-.4M14.17,11.348v.115a.4.4,0,1,1-.805,0v-.072a1.526,1.526,0,0,1-.636-.264.4.4,0,0,1,.441-.674.81.81,0,0,0,.6.155.455.455,0,0,0,.445-.384.326.326,0,0,0-.243-.39,3.622,3.622,0,0,1-.992-.478.971.971,0,0,1-.324-.953,1.076,1.076,0,0,1,.708-.832V7.482a.4.4,0,0,1,.8,0v.065a1.345,1.345,0,0,1,.442.211.4.4,0,1,1-.482.645.554.554,0,0,0-.5-.071.277.277,0,0,0-.18.22.192.192,0,0,0,.03.17,3.075,3.075,0,0,0,.763.353,1.133,1.133,0,0,1,.769,1.283,1.282,1.282,0,0,1-.841.99"
            fill="#165EEB"
          />
          <path
            id="Path_183636"
            data-name="Path 183636"
            d="M27.641,4.227a.753.753,0,0,0-1.061.013l-.973,1v-1.6C25.607,1.329,24.326,0,22.092,0H6.108C6.022,0,5.934,0,5.851,0A3.57,3.57,0,0,0,2.368,3.638V7.294a.75.75,0,0,0,1.5,0V3.621A2.073,2.073,0,0,1,5.891,1.5a1.69,1.69,0,0,1,.185,0H22.092c1.393,0,2.015.659,2.015,2.135v1.6l-.975-1a.751.751,0,0,0-1.074,1.049L24.32,7.6a.75.75,0,0,0,1.073,0l2.261-2.315a.752.752,0,0,0-.013-1.062"
            fill="#165EEB"
          />
          <path
            id="Path_183637"
            data-name="Path 183637"
            d="M24.75,9.709a.75.75,0,0,0-.75.75v3.675a2.073,2.073,0,0,1-2.023,2.119c-.048,0-.1,0-.185,0H5.776c-1.394,0-2.015-.658-2.015-2.134v-1.6l.975,1a.75.75,0,0,0,1.073-1.049L3.548,10.15a.772.772,0,0,0-1.073,0L.214,12.466a.75.75,0,0,0,1.073,1.049l.974-1v1.6c0,2.31,1.281,3.634,3.515,3.634H21.75c.06,0,.12,0,.18,0h.086A3.57,3.57,0,0,0,25.5,14.116V10.459a.75.75,0,0,0-.75-.75"
            fill="#165EEB"
          />
        </g>
      </g>
    </svg>
  );
};

export default TransferIcon;
