import React from 'react'

const ComprasB2BIconBlue = () => {
  return (
        <svg id="compras_b2b" data-name="compras b2b" xmlns="http://www.w3.org/2000/svg" width="25.987" height="25.987" viewBox="0 0 25.987 25.987">
         <path id="Trazado_183094" data-name="Trazado 183094" d="M20.07,0H5.916A2.03,2.03,0,0,0,4.481.595L.595,4.481A2.03,2.03,0,0,0,0,5.916V7.474a2.677,2.677,0,0,0,2.677,2.677h0A2.677,2.677,0,0,0,5.355,7.474V5.837a.761.761,0,0,1,.761-.761h0a.761.761,0,0,1,.761.761V7.474a2.677,2.677,0,0,0,2.677,2.677h0a2.677,2.677,0,0,0,2.677-2.677V5.837a.761.761,0,0,1,.761-.761h0a.761.761,0,0,1,.761.761V7.474a2.677,2.677,0,0,0,2.677,2.677h0a2.677,2.677,0,0,0,2.677-2.677V5.837a.761.761,0,0,1,.761-.761h0a.761.761,0,0,1,.761.761V7.474a2.677,2.677,0,0,0,2.677,2.677h0a2.677,2.677,0,0,0,2.677-2.677V5.916a2.03,2.03,0,0,0-.595-1.436L21.506.595A2.03,2.03,0,0,0,20.07,0Z" transform="translate(0 0)" fill="#165EEB"/>
         <path id="Trazado_183095" data-name="Trazado 183095" d="M44.8,232.03v3.045a2.03,2.03,0,0,1-2.03,2.03H31.559a2.03,2.03,0,0,1-2.03-2.03V232.03A2.03,2.03,0,0,0,27.5,230h0a2.03,2.03,0,0,0-2.03,2.03v10.253a2.03,2.03,0,0,0,2.03,2.03H46.833a2.03,2.03,0,0,0,2.03-2.03V232.03a2.03,2.03,0,0,0-2.03-2.03h0A2.03,2.03,0,0,0,44.8,232.03Zm-2.03,9.136H31.559a1.015,1.015,0,0,1-1.015-1.015h0a1.015,1.015,0,0,1,1.015-1.015H42.772a1.015,1.015,0,0,1,1.015,1.015h0A1.015,1.015,0,0,1,42.772,241.166Z" transform="translate(-24.175 -218.326)" fill="#165EEB"/>
        </svg>
  )
}

export default ComprasB2BIconBlue
