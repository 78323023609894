import React from 'react';

const DeleteIcon = () => {
  return (
    <svg id="Delete" xmlns="http://www.w3.org/2000/svg" width="19.36" height="23.231" viewBox="0 0 19.36 23.231">
      <path
        id="Path_183284"
        data-name="Path 183284"
        d="M27.069,8.581H22.552V7.936A1.938,1.938,0,0,0,20.616,6H16.744a1.938,1.938,0,0,0-1.936,1.936v.645H10.291A1.292,1.292,0,0,0,9,9.872v1.291a1.292,1.292,0,0,0,1.291,1.291H27.069a1.292,1.292,0,0,0,1.291-1.291V9.872A1.292,1.292,0,0,0,27.069,8.581Zm-5.808,0H16.1V7.936a.646.646,0,0,1,.645-.645h3.872a.646.646,0,0,1,.645.645Z"
        transform="translate(-9 -6)"
        fill="#165eeb"
      />
      <path
        id="Path_183285"
        data-name="Path 183285"
        d="M12.3,30.476a3.236,3.236,0,0,0,3.22,3.012h8.077a3.236,3.236,0,0,0,3.22-3.012L27.643,18H11.464Zm10.488-9.959a.645.645,0,1,1,1.284.129L23.422,27.1a.645.645,0,1,1-1.284-.129Zm-3.875.065a.645.645,0,0,1,1.291,0v6.453a.645.645,0,0,1-1.291,0Zm-3.291-.642a.644.644,0,0,1,.706.578l.645,6.453a.645.645,0,1,1-1.284.129l-.645-6.453A.645.645,0,0,1,15.617,19.939Z"
        transform="translate(-9.874 -10.256)"
        fill="#165eeb"
      />
    </svg>
  );
};

export default DeleteIcon;
