import React from 'react';

const BlindEyeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.407" height="12" viewBox="0 0 14.407 12">
      <path
        id="Trazado_183429"
        data-name="Trazado 183429"
        d="M8.2,5.246a3.234,3.234,0,0,1,3.117,4.23l2,2a7.728,7.728,0,0,0,2.082-2.966A7.752,7.752,0,0,0,5.82,3.982L7.241,5.4A3.366,3.366,0,0,1,8.2,5.246ZM2.12,3.059a.652.652,0,0,0,0,.923l1.29,1.29A7.787,7.787,0,0,0,1,8.52a7.727,7.727,0,0,0,10.026,4.374l1.781,1.781a.653.653,0,0,0,.923-.923L3.05,3.059A.66.66,0,0,0,2.12,3.059ZM8.2,11.795A3.275,3.275,0,0,1,4.929,8.52a3.216,3.216,0,0,1,.321-1.4L6.278,8.147a2.249,2.249,0,0,0-.039.373A1.962,1.962,0,0,0,8.2,10.485a1.764,1.764,0,0,0,.373-.046L9.6,11.467A3.152,3.152,0,0,1,8.2,11.795ZM10.148,8.3A1.944,1.944,0,0,0,8.419,6.576Z"
        transform="translate(-1 -2.868)"
        fill="#4f4f4f"
      />
    </svg>
  );
};

export default BlindEyeIcon;
