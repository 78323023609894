import React from 'react';

const CloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.435" height="13.435" viewBox="0 0 13.435 13.435">
      <g id="Grupo_29596" data-name="Grupo 29596" transform="translate(-24 -22)">
        <rect
          id="Rectángulo_8219"
          data-name="Rectángulo 8219"
          width="17"
          height="2"
          rx="1"
          transform="translate(25.414 22) rotate(45)"
          fill="#165EEB"
        />
        <rect
          id="Rectángulo_8221"
          data-name="Rectángulo 8221"
          width="17"
          height="2"
          rx="1"
          transform="translate(24 34.021) rotate(-45)"
          fill="#165EEB"
        />
      </g>
    </svg>
  );
};

export default CloseIcon;
