import React from 'react';

const B2CDelivered = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" style={{ display: 'inline' }}>
      <g id="Grupo_32886" data-name="Grupo 32886" transform="translate(-20 -600)">
        <rect
          id="Rectángulo_9822"
          data-name="Rectángulo 9822"
          width="25"
          height="25"
          transform="translate(20 600)"
          fill="none"
        />
        <path
          id="order-delivered"
          d="M12.955,2A7.955,7.955,0,0,0,5,9.955c0,5.682,7.955,14.773,7.955,14.773s7.955-9.091,7.955-14.773A7.955,7.955,0,0,0,12.955,2ZM17.5,11.091H16.364V14.5H14.091V11.091H11.818V14.5H9.545V11.091H8.409V9.955l4.545-3.409L17.5,9.955Z"
          transform="translate(19.545 599.136)"
          fill="#009cde"
        />
      </g>
    </svg>
  );
};

export default B2CDelivered;
