import React, { CSSProperties } from 'react';

const TelefonoIcon = ({style}:{style?:CSSProperties}) => {
  return (
    <svg id="telefono" xmlns="http://www.w3.org/2000/svg" width="16.218" height="18.997" viewBox="0 0 16.218 18.997" style={style}>
      <path
        id="Trazado_183425"
        data-name="Trazado 183425"
        d="M24165.289,3328.079h-7.205a2.71,2.71,0,0,1-2.561-2.833v-13.332a2.71,2.71,0,0,1,2.561-2.833h7.205a2.713,2.713,0,0,1,2.566,2.833v13.332A2.713,2.713,0,0,1,24165.289,3328.079Zm-3.6-4.312a.971.971,0,1,0,.947.97A.961.961,0,0,0,24161.686,3323.766Zm-4-12.355a.5.5,0,0,0,0,1h8a.5.5,0,0,0,0-1Z"
        transform="translate(-24155.523 -3309.082)"
        fill="#002254"
      />
      <path
        id="Trazado_182955"
        data-name="Trazado 182955"
        d="M13.413,10.536a.5.5,0,0,1-.4-.8,1.925,1.925,0,0,0,.436-1.768,1.64,1.64,0,0,0-.374-.636.5.5,0,1,1,.676-.737A2.592,2.592,0,0,1,14.4,7.667a2.866,2.866,0,0,1-.59,2.67A.5.5,0,0,1,13.413,10.536Z"
        transform="translate(0.116 0.998)"
        fill="#002254"
      />
      <path
        id="Trazado_182956"
        data-name="Trazado 182956"
        d="M14.424,12.183a.5.5,0,0,1-.378-.827,4.378,4.378,0,0,0,0-5.7A.5.5,0,0,1,14.8,5a5.378,5.378,0,0,1,0,7.011A.5.5,0,0,1,14.424,12.183Z"
        transform="translate(0.116 0.999)"
        fill="#002254"
      />
    </svg>
  );
};

export default TelefonoIcon;
