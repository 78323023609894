import React from 'react';

const VisaIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="58"
      height="35"
      viewBox="0 0 58 35"
    >
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectángulo_9524" data-name="Rectángulo 9524" width="58" height="35" rx="2" fill="#fff" />
        </clipPath>
        <clipPath id="clip-path-2">
          <rect id="Rectángulo_9523" data-name="Rectángulo 9523" width="58" height="38.281" fill="#fff" />
        </clipPath>
      </defs>
      <g id="visa" clip-path="url(#clip-path)">
        <rect id="Rectángulo_9522" data-name="Rectángulo 9522" width="58" height="35" rx="2" fill="#a09b9b" />
        <g id="Grupo_31905" data-name="Grupo 31905" transform="translate(0 -2.333)" clip-path="url(#clip-path-2)">
          <path
            id="Trazado_184091"
            data-name="Trazado 184091"
            d="M0,.734A2.727,2.727,0,0,1,2.719-2H55.281A2.727,2.727,0,0,1,58,.734V33.547a2.727,2.727,0,0,1-2.719,2.734H2.719A2.727,2.727,0,0,1,0,33.547Z"
            transform="translate(0 2)"
            fill="#f4f6f7"
            fillRule="evenodd"
          />
          <path
            id="Trazado_184092"
            data-name="Trazado 184092"
            d="M0,.734A2.727,2.727,0,0,1,2.719-2H55.281A2.727,2.727,0,0,1,58,.734v6.38H0Z"
            transform="translate(0 2)"
            fill="#4095db"
            fillRule="evenodd"
          />
          <path
            id="Trazado_184093"
            data-name="Trazado 184093"
            d="M0,23H58v6.38a2.727,2.727,0,0,1-2.719,2.734H2.719A2.727,2.727,0,0,1,0,29.38Z"
            transform="translate(0 6.167)"
            fill="#f4b662"
            fillRule="evenodd"
          />
          <path
            id="Trazado_184094"
            data-name="Trazado 184094"
            d="M35,9.456a6.417,6.417,0,0,0-2.558-.519c-2.822,0-4.808,1.678-4.825,4.08-.018,1.777,1.418,2.768,2.5,3.358,1.112.605,1.486.993,1.481,1.534-.008.828-.888,1.207-1.708,1.207a5.225,5.225,0,0,1-2.689-.648l-.367-.2-.4,2.767a7.84,7.84,0,0,0,3.18.659c3,0,4.948-1.657,4.97-4.222.01-1.409-.75-2.477-2.4-3.359-1-.573-1.61-.953-1.6-1.533,0-.514.517-1.064,1.635-1.064a4.567,4.567,0,0,1,2.136.475l.256.142ZM21.8,21.515,23.583,9.153h2.854L24.652,21.515Zm18.145-7.838s-.9,2.745-1.137,3.455h2.37c-.113-.618-.661-3.568-.661-3.568L40.322,12.5c-.076.245-.182.569-.263.816h0C39.986,13.534,39.934,13.692,39.942,13.677Zm2.384-4.511H40.121a1.4,1.4,0,0,0-1.5,1.025L34.386,21.519h3s.489-1.524.6-1.858l1.647,0,2.009,0c.086.431.348,1.852.348,1.852h2.649Zm-22.924,0-2.794,8.428-.3-1.713h0l-1-5.679a1.22,1.22,0,0,0-1.294-1.043h-4.6l-.038.256a10.951,10.951,0,0,1,3,1.356L14.912,21.5l3.021,0L22.427,9.163Z"
            transform="translate(1.5 3.823)"
            fill="#0071ce"
            fillRule="evenodd"
          />
          <path
            id="Trazado_184095"
            data-name="Trazado 184095"
            d="M0-.25A1.745,1.745,0,0,1,1.74-2H35.38A1.745,1.745,0,0,1,37.12-.25v21a1.745,1.745,0,0,1-1.74,1.75H1.74A1.745,1.745,0,0,1,0,20.75Z"
            transform="translate(0 2)"
            fill="rgba(229,229,229,0.01)"
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
};

export default VisaIcon;
