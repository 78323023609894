import React from 'react';

interface Icon {
  color?: string
}

const MisVentasECommerceIcon = ({color}: Icon) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10">
      <path id="Mis_ventas_ecommerce" data-name="Mis ventas ecommerce" d="M2.5,4A1.251,1.251,0,0,0,1.25,5.25V11.5A1.251,1.251,0,0,0,2.5,12.75H0V14H15V12.75H12.5a1.251,1.251,0,0,0,1.25-1.25V5.25A1.251,1.251,0,0,0,12.5,4Zm0,1.25h10V11.5H2.5ZM5.625,6.5A.625.625,0,0,0,5,7.125v2.5a.625.625,0,0,0,.625.625h3.75A.625.625,0,0,0,10,9.625v-2.5A.625.625,0,0,0,9.375,6.5H8.125V7.75H6.875V6.5Z" transform="translate(0 -4)" fill={color ? color: '#165EEB'}/>
    </svg>
  );
};

export default MisVentasECommerceIcon;
