import React from 'react';
import { IIcon } from '../interfaces/IIcon';

const CreditCardIcon = ({ height = '26.947', width = '17.443' }: IIcon) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}  viewBox="0 0 54 35.308">
        <g id="Grupo_31409" data-name="Grupo 31409" transform="translate(-2 -7)">
            <path id="Trazado_183865" data-name="Trazado 183865" d="M2,11.868A4.868,4.868,0,0,1,6.868,7H51.132A4.868,4.868,0,0,1,56,11.868v3.539H2Z" transform="translate(0 0)" fill="#165eeb" fillRule="evenodd"/>
            <path id="Trazado_183866" data-name="Trazado 183866" d="M2,19.952H56v3.539a4.868,4.868,0,0,1-4.868,4.868H6.868A4.868,4.868,0,0,1,2,23.491Z" transform="translate(0 13.949)" fill="#165eeb" fillRule="evenodd"/>
            <rect id="Rectángulo_9415" data-name="Rectángulo 9415" width="6" height="5.978" rx="1" transform="translate(9.5 21.058)" fill="#165eeb"/>
            <rect id="Rectángulo_9416" data-name="Rectángulo 9416" width="31.5" height="2.989" rx="0.72" transform="translate(20 22.552)" fill="#165eeb"/>
        </g>
        </svg>
  );
};

export default CreditCardIcon;
