import React from 'react'

const NuevasSolicitudesIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24.69" height="26" viewBox="0 0 24.69 26">
            <path id="nuevas_solicitudes_icon" data-name="nuevas solicitudes icon" d="M7.2,2,2,2.019l.013,2.6L4.6,4.606V17.6a3.919,3.919,0,0,0,3.9,3.9h18.2V18.9H8.5a1.279,1.279,0,0,1-1.3-1.3Zm5.2,1.3V8.5h13V3.3ZM9.8,11.1v5.2h14.3V11.1Zm0,11.7a2.6,2.6,0,1,0,2.6,2.6A2.6,2.6,0,0,0,9.8,22.8Zm13,0a2.6,2.6,0,1,0,2.6,2.6A2.6,2.6,0,0,0,22.795,22.8Z" transform="translate(-2.004 -1.996)" fill="#165eeb" />
        </svg>

    )
}

export default NuevasSolicitudesIcon