import React from 'react';

const CompraRapida = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.762" height="16.173" viewBox="0 0 11.762 16.173">
      <path
        id="compra_rapida_icono"
        data-name="compra rapida icono"
        d="M6.205,1V7.012l1.47-.806V1ZM9.146,1V5.509a2.93,2.93,0,0,1,1.47,0V1Zm2.941,0V6.206l1.47.806V1ZM9.88,6.881a1.482,1.482,0,0,0-.693.175L4,9.9V14.16L9.195,17a1.458,1.458,0,0,0,.683.171,1.494,1.494,0,0,0,.7-.175l5.185-2.839V9.9L10.568,7.052A1.464,1.464,0,0,0,9.88,6.881ZM5.47,11.526l3.676,1.862V15.3L5.47,13.288Zm8.822,0v1.763l-3.676,2.02V13.387Z"
        transform="translate(-4 -1)"
        fill="#fff"
      />
    </svg>
  );
};

export default CompraRapida;
