import React from 'react';

const ArrowRightIcon = ({color}: {color?: string}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10.576" height="18.978" viewBox="0 0 10.576 18.978">
      <path
        id="Trazado_182364"
        data-name="Trazado 182364"
        d="M0,0,8.8,8.467,17.591,0"
        transform="translate(0.72 18.285) rotate(-90)"
        fill="none"
        stroke={color ? color :"#fff"}
        stroke-width="2"
      />
    </svg>
  );
};

export default ArrowRightIcon;
