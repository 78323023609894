import React from 'react';

const ArrowUp = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.414" height="12.121" viewBox="0 0 21.414 12.121">
        <path id="drop_azul" data-name="drop azul" d="M10,0,0,10,10,20" transform="translate(20.707 1.414) rotate(90)" fill="none" stroke="#000255" stroke-width="2"/>
    </svg>
  );
};

export default ArrowUp;
