import { ctpClient } from './BuildClient';
import { ApiRoot, createApiBuilderFromCtpClient } from '@commercetools/platform-sdk';
import CONST_PROJECT from '@/shared/consts/CONST_PROJECT.json'
import { CTCustomer } from './customer';

// Create apiRoot from the imported ClientBuilder and include your Project key
export const apiRoot = createApiBuilderFromCtpClient(ctpClient).withProjectKey({ projectKey: CONST_PROJECT.PROJECT_KEY });

export const ctCustomer = (email: string, password: string) => {
    return new CTCustomer().passwordFlow(email, password)
}

// // Example call to return Project information
// // This code has the same effect as sending a GET request to the commercetools Composable Commerce API without any endpoints.
// const getProject = () => {
//   return apiRoot.get().execute();;
// };

// // Retrieve Project information and output the result to the log
