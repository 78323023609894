import React from 'react';

const NotificationIcon = () => {
  return (
    <svg id="Campana" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
      <path id="user.281a567b" d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Z" fill="#165EEB" />
      <path
        id="notifications_FILL1_wght400_GRAD0_opsz48"
        d="M160.668-864.854a.649.649,0,0,1-.479-.189.649.649,0,0,1-.189-.479.649.649,0,0,1,.189-.479.649.649,0,0,1,.479-.189h1.114v-6.771a5.472,5.472,0,0,1,1.1-3.352,4.718,4.718,0,0,1,2.907-1.86v-.49a1.289,1.289,0,0,1,.39-.947,1.289,1.289,0,0,1,.947-.39,1.289,1.289,0,0,1,.947.39,1.289,1.289,0,0,1,.39.947v.49a4.718,4.718,0,0,1,2.907,1.86,5.472,5.472,0,0,1,1.1,3.352v6.771h1.114a.649.649,0,0,1,.479.189.649.649,0,0,1,.189.479.649.649,0,0,1-.189.479.649.649,0,0,1-.479.189Zm6.46,2.673a1.716,1.716,0,0,1-1.258-.523,1.716,1.716,0,0,1-.523-1.259h3.564a1.716,1.716,0,0,1-.523,1.259A1.716,1.716,0,0,1,167.128-862.181Z"
        transform="translate(-152 886)"
        fill="#fff"
      />
    </svg>
  );
};

export default NotificationIcon;
