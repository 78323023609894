import React from 'react';

interface Icon {
  color?: string
}
const MiLineaCreditoIcon = ({color}: Icon) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.999" height="14.721" viewBox="0 0 14.999 14.721">
      <g id="movimientos" transform="translate(13529.749 3515.75)">
        <path id="Trazado_184046" data-name="Trazado 184046" d="M3600.243-3448.935l-3.226-3.225a.909.909,0,0,1-.266-.645.915.915,0,0,1,.266-.642l3.226-3.226a.906.906,0,0,1,.643-.267.9.9,0,0,1,.643.267.9.9,0,0,1,.266.644.9.9,0,0,1-.266.643l-1.672,1.673h8.824a.911.911,0,0,1,.91.908.911.911,0,0,1-.91.91h-8.824l1.672,1.673a.9.9,0,0,1,.266.643.9.9,0,0,1-.266.644.9.9,0,0,1-.643.266A.9.9,0,0,1,3600.243-3448.935Zm6.631-6.463a.9.9,0,0,1-.272-.642.894.894,0,0,1,.272-.643l1.706-1.67h-9a.92.92,0,0,1-.928-.909.92.92,0,0,1,.928-.908h9l-1.706-1.67a.894.894,0,0,1-.272-.643.893.893,0,0,1,.272-.642.937.937,0,0,1,.656-.266.936.936,0,0,1,.656.266l3.291,3.221a.892.892,0,0,1,.272.642.905.905,0,0,1-.272.643l-3.291,3.22a.932.932,0,0,1-.656.266A.936.936,0,0,1,3606.875-3455.4Zm4.358-3.863a.676.676,0,0,1-.045.244.427.427,0,0,0,.077-.244.43.43,0,0,0-.078-.244A.657.657,0,0,1,3611.233-3459.261Z" transform="translate(-17126.5 -52.361)" fill={color ? color: '#165EEB'}/>
      </g>
    </svg>
  );
};

export default MiLineaCreditoIcon;
