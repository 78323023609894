import React from 'react';

function PlusIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
      <path
        id="Trazado_183582"
        data-name="Trazado 183582"
        d="M4.422-3.088V-6.416H1.176V-7.858H4.422v-3.23H5.93v3.23H9.176v1.443H5.93v3.328Z"
        transform="translate(-1.176 11.088)"
        fill="#165eeb"
      />
    </svg>
  );
}

export default PlusIcon;
