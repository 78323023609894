import React from 'react';

const UpdateIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.84" height="14.723" viewBox="0 0 14.84 14.723">
        <path id="Trazado_183888" data-name="Trazado 183888" d="M38.582,7.66,37.408,6.486a1.662,1.662,0,0,0-2.347,0L25.3,16.248a.829.829,0,0,0-.243.587l-.83,3.06a.829.829,0,0,0,.243.587,1.521,1.521,0,0,0,.587.243l3.177-.713a.829.829,0,0,0,.587-.243l9.762-9.762A1.66,1.66,0,0,0,38.582,7.66Z" transform="translate(-24.227 -6.001)" fill="#165eeb"/>
    </svg>
  );
};

export default UpdateIcon;
