import React from "react";
const ShoppingCartEmptyIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="55.525" height="55" viewBox="0 0 55.525 55">
        <path id="handcart" d="M42.322,2.017a5.427,5.427,0,0,0-2.127.252L24.477,7.108a5.5,5.5,0,0,0-3.523,6.8l3.706,12.54a5.43,5.43,0,0,0,2.728,3.228,5.522,5.522,0,0,0,2.5.607,5.238,5.238,0,0,0,1.659-.263L47.3,25.174a5.5,5.5,0,0,0,3.523-6.8l-3.7-12.535A5.448,5.448,0,0,0,44.385,2.6,5.532,5.532,0,0,0,42.322,2.017ZM12.157,4.342,1.7,7.688l1.676,5.236,5.2-1.659,8.26,26.213a8.292,8.292,0,0,0,10.349,5.387L57.22,33.418l-1.654-5.247L25.529,37.617a2.7,2.7,0,0,1-3.448-1.794ZM38.278,8.633l1.654,5.242-7.868,2.481-1.654-5.242ZM27.274,46a5.5,5.5,0,1,0,5.5,5.5,5.5,5.5,0,0,0-5.5-5.5Z" transform="translate(-1.695 -1.999)" fill="#828384"/>
      </svg>
    )
}

export default ShoppingCartEmptyIcon;
