import React from 'react';

const CartIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30.007" height="30.007" viewBox="0 0 30.007 30.007">
      <g id="carrito_icono" data-name="carrito icono" transform="translate(0 0)">
        <path
          id="Trazado_183224"
          data-name="Trazado 183224"
          d="M40.007,15.221H15.792l-.4-2.643A3.075,3.075,0,0,0,12.5,10H10v2.61h2.865l3.161,20.9a1.539,1.539,0,0,0,1.446,1.29H37.506v-2.61H18.358l-.4-2.611H37.506Z"
          transform="translate(-10 -10)"
          fill="#002554"
        />
        <path
          id="Trazado_183225"
          data-name="Trazado 183225"
          d="M34.166,78.75a2.083,2.083,0,1,1-2.083-2.083A2.084,2.084,0,0,1,34.166,78.75Z"
          transform="translate(-22.248 -50.826)"
          fill="#002554"
        />
        <path
          id="Trazado_183226"
          data-name="Trazado 183226"
          d="M64.166,78.75a2.083,2.083,0,1,1-2.083-2.083A2.084,2.084,0,0,1,64.166,78.75Z"
          transform="translate(-40.619 -50.826)"
          fill="#002554"
        />
      </g>
    </svg>
  );
};

export default CartIcon;
