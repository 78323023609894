import React from 'react';

const ErrorIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40.001" viewBox="0 0 40 40.001">
      <path
        id="error"
        d="M-18570,41a19.874,19.874,0,0,1-14.141-5.859A19.872,19.872,0,0,1-18590,21a20.026,20.026,0,0,1,20-20,20.023,20.023,0,0,1,20,20,19.872,19.872,0,0,1-5.859,14.141A19.867,19.867,0,0,1-18570,41Zm0-17.072h0l7.75,7.747,2.934-2.922-7.754-7.754,7.754-7.754-2.93-2.922-7.754,7.748-7.754-7.748-2.922,2.922,7.75,7.754-7.75,7.754,2.922,2.922,7.754-7.747Z"
        transform="translate(18590.004 -0.998)"
        fill="#f42f43"
      />
    </svg>
  );
};

export default ErrorIcon;
