import React from 'react';

const B2CCompleted = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" style={{ display: 'inline' }}>
      <g id="Grupo_32887" data-name="Grupo 32887" transform="translate(-20 -648)">
        <rect
          id="Rectángulo_9823"
          data-name="Rectángulo 9823"
          width="25"
          height="25"
          transform="translate(20 648)"
          fill="none"
        />
        <path
          id="Sustracción_33"
          data-name="Sustracción 33"
          d="M17.316,22.727a5.411,5.411,0,1,1,5.411-5.411A5.417,5.417,0,0,1,17.316,22.727Zm-2.308-6.871h0L13.4,17.463l3.253,3.254,4.572-4.573L19.62,14.537,16.654,17.5l-1.647-1.647ZM10.06,19.481h-7.9A2.167,2.167,0,0,1,0,17.316V4.329a1.082,1.082,0,0,1,.147-.545L2.041.537A1.087,1.087,0,0,1,2.976,0H16.5a1.087,1.087,0,0,1,.935.537l1.894,3.247a1.082,1.082,0,0,1,.147.545v5.73A7.7,7.7,0,0,0,18.4,9.82v.007a7.436,7.436,0,0,0-1.082-.087A7.522,7.522,0,0,0,9.826,18.4v0l-.007,0a7.477,7.477,0,0,0,.239,1.082ZM6.493,6.493V8.658h6.493V6.493ZM3.6,2.164,2.336,4.329H17.145L15.883,2.164H3.6Z"
          transform="translate(21.137 649.136)"
          fill="#168374"
        />
      </g>
    </svg>
  );
};

export default B2CCompleted;
