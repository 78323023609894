import React from 'react';

const SuccessIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <g id="exito" transform="translate(-2 -2)">
        <path
          id="Trazado_182674"
          data-name="Trazado 182674"
          d="M22,2A20,20,0,1,0,42,22,20.007,20.007,0,0,0,22,2ZM18,32,8,22l2.82-2.82L18,26.34,33.18,11.16,36,14Z"
          transform="translate(0 0)"
          fill="#28a745"
        />
      </g>
    </svg>
  );
};

export default SuccessIcon;
