import React from 'react';

const B2CShipping = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" style={{ display: 'inline' }}>
      <g id="Grupo_32906" data-name="Grupo 32906" transform="translate(-20 -553)">
        <rect
          id="Rectángulo_9821"
          data-name="Rectángulo 9821"
          width="25"
          height="25"
          transform="translate(20 553)"
          fill="none"
        />
        <path
          id="in-transit"
          d="M0,5V6.976H6.917V8.953H.988v1.976H4.941v1.976H.988v4.941H2.964a2.964,2.964,0,0,0,5.929,0h5.929a2.964,2.964,0,0,0,5.929,0h1.976V12.905L20.035,7.522a.984.984,0,0,0-.882-.546H15.81A1.976,1.976,0,0,0,13.834,5ZM15.81,8.706h2.883l2.1,4.2H15.81ZM5.929,16.611a1.235,1.235,0,1,1-1.235,1.235A1.237,1.237,0,0,1,5.929,16.611Zm11.858,0a1.235,1.235,0,1,1-1.235,1.235A1.237,1.237,0,0,1,17.787,16.611Z"
          transform="translate(21.136 552.545)"
          fill="#0288a3"
        />
      </g>
    </svg>
  );
};

export default B2CShipping;
