import React from 'react';

interface Icon {
  color?: string
}

const ComunicadosIcon = ({color}: Icon) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10.92" height="15" viewBox="0 0 10.92 15">
      <g id="comunicados" transform="translate(16627.545 24849.922)">
        <g id="_x32_1_Online_Shopping_x2C__Cart_x2C__Online_Shop_x2C__Shopping" transform="translate(-16627.545 -24849.922)">
          <path id="Trazado_183248" data-name="Trazado 183248" d="M2.046,1.364H6.171V0H2.046A2.052,2.052,0,0,0,0,2.046V12.955A2.052,2.052,0,0,0,2.046,15H8.864a2.052,2.052,0,0,0,2.046-2.046v-6.1H9.546v6.1a.684.684,0,0,1-.682.682H2.046a.684.684,0,0,1-.682-.682V2.046A.684.684,0,0,1,2.046,1.364ZM8.182,12.273H2.727V10.909H8.182ZM2.727,8.182H8.182V9.546H2.727Zm0-2.727H6.182V6.818H2.727Z" transform="translate(0 0)" fill={color ? color: '#165EEB'}/>
        </g>
        <path id="collections_bookmark_FILL0_wght400_GRAD0_opsz24" d="M283.1-846.055v5.491l-1.686-1.226-1.7,1.226v-5.491Zm-3.391,0h0Z" transform="translate(-16899.723 -24003.867)" fill={color ? color: '#165EEB'}/>
      </g>
    </svg>
  );
};

export default ComunicadosIcon;
