import React from 'react';

interface Icon {
  color?: string
}
const StockIcon = ({color}: Icon) => {
  return (
    <svg
      id="glyph_ecommerce_22_Stock"
      data-name="glyph_ecommerce_22 Stock"
      xmlns="http://www.w3.org/2000/svg"
      width="20.005"
      height="20.005"
      viewBox="0 0 20.005 20.005"
    >
      <path
        id="Trazado_183140"
        data-name="Trazado 183140"
        d="M143.353,0h-1.582a.391.391,0,0,0-.391.391V4.3a.391.391,0,0,1-.391.391h-1.563a.391.391,0,0,1-.391-.391V.391A.391.391,0,0,0,138.645,0h-1.582A1.563,1.563,0,0,0,135.5,1.563v6.29a1.563,1.563,0,0,0,1.563,1.563h6.29a1.563,1.563,0,0,0,1.563-1.563V1.563A1.563,1.563,0,0,0,143.353,0Z"
        transform="translate(-130.206)"
        fill={color ? color : "#002554" }
      />
      <path
        id="Trazado_183141"
        data-name="Trazado 183141"
        d="M7.853,271H6.271a.391.391,0,0,0-.391.391V275.3a.391.391,0,0,1-.391.391H3.927a.391.391,0,0,1-.391-.391v-3.907A.391.391,0,0,0,3.145,271H1.563A1.563,1.563,0,0,0,0,272.563v6.29a1.563,1.563,0,0,0,1.563,1.563h6.29a1.563,1.563,0,0,0,1.563-1.563v-6.29A1.563,1.563,0,0,0,7.853,271Z"
        transform="translate(0 -260.412)"
        fill={color ? color : "#002554" }
      />
      <path
        id="Trazado_183142"
        data-name="Trazado 183142"
        d="M278.853,271h-1.582a.391.391,0,0,0-.391.391V275.3a.391.391,0,0,1-.391.391h-1.563a.391.391,0,0,1-.391-.391v-3.907a.391.391,0,0,0-.391-.391h-1.582A1.563,1.563,0,0,0,271,272.563v6.29a1.563,1.563,0,0,0,1.563,1.563h6.29a1.563,1.563,0,0,0,1.563-1.563v-6.29A1.563,1.563,0,0,0,278.853,271Z"
        transform="translate(-260.412 -260.412)"
        fill={color ? color : "#002554" }
      />
    </svg>
  );
};

export default StockIcon;
