import {
    ClientBuilder,
    type HttpMiddlewareOptions,
    type PasswordAuthMiddlewareOptions
} from '@commercetools/sdk-client-v2'
import CONST_PROJECT from "@/shared/consts/CONST_PROJECT.json"

import { createApiBuilderFromCtpClient } from '@commercetools/platform-sdk'

export class CTCustomer {
    private httpMiddlewareOption: HttpMiddlewareOptions

    constructor(){
        this.httpMiddlewareOption = {
            host: CONST_PROJECT.HOST,
            fetch: globalThis.fetch
        }
    }

    passwordFlow(username: string, password: string) {
        const authMiddlewareOptions: PasswordAuthMiddlewareOptions = {
            host: CONST_PROJECT.AUTH_URL,
            projectKey: CONST_PROJECT.PROJECT_KEY,
            credentials: {
                clientId: CONST_PROJECT.CLIENT_ID,
                clientSecret: CONST_PROJECT.CLIENT_SECRET,
                user: {
                    username,
                    password,
                },
            },
            scopes: [CONST_PROJECT.SCOPES],
            fetch: globalThis.fetch
        }
        const client = new ClientBuilder()
            .withProjectKey(CONST_PROJECT.PROJECT_KEY)
            .withPasswordFlow(authMiddlewareOptions)
            .withHttpMiddleware(this.httpMiddlewareOption)
            .build()
        return createApiBuilderFromCtpClient(client)
            .withProjectKey({ projectKey: CONST_PROJECT.PROJECT_KEY })
    }
}