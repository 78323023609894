import React from "react"

interface ISvgProps {
    width?: number,
    heigth?: number,
    color?: string
}

const ListaCompras = ({
    width = 18.034,
    heigth = 21.984,
    color = "#165EEB"
}: ISvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={heigth}
    data-name="Lista de compras icono"
  >
    <path
      fill={color}
      d="M16.746 21.984H1.288a1.288 1.288 0 0 0 1.288-1.288.859.859 0 0 1 .859-.859h13.74a.859.859 0 0 1 .859.859 1.288 1.288 0 0 1-1.288 1.288Z"
      data-name="Trazado 183120"
    />
    <path
      fill={color}
      d="M15.428.636a1.331 1.331 0 0 1-.775.223C13.606.859 13.606 0 12.559 0s-1.047.859-2.093.859S9.419 0 8.373 0 7.326.859 6.28.859 5.233 0 4.186 0 3.14.859 2.093.859a1.331 1.331 0 0 1-.775-.223A.857.857 0 0 0 0 1.363V20.01a.686.686 0 0 0 .721.685.7.7 0 0 0 .632-.525 2.151 2.151 0 0 1 2.082-1.621h12.452a.859.859 0 0 0 .859-.859V1.363a.857.857 0 0 0-1.318-.727ZM9.661 13.783a.859.859 0 0 1-.859.859H3.65a.859.859 0 0 1-.859-.859.859.859 0 0 1 .859-.859H8.8a.859.859 0 0 1 .859.859Zm3.439-3.006H3.65a.859.859 0 1 1 0-1.718h9.45a.859.859 0 0 1 0 1.718Zm0-3.864H3.65a.859.859 0 1 1 0-1.718h9.45a.859.859 0 1 1 0 1.718Z"
      data-name="Trazado 183121"
    />
  </svg>
)

export default ListaCompras;
