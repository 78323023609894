import React from "react";
const QuestionIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="Grupo_31308" data-name="Grupo 31308" transform="translate(-943 -118)">
                <circle id="Elipse_455" data-name="Elipse 455" cx="10" cy="10" r="10" transform="translate(943 118)" fill="#165eeb"/>
                <path id="Trazado_183863" data-name="Trazado 183863" d="M4.694-15.356c-2.163,0-3.594,1.008-3.594,2.488a2.078,2.078,0,0,0,.146.8.644.644,0,0,0,.667.407h.846a.522.522,0,0,0,.569-.569c.016-.325.049-.651.065-.878a2.868,2.868,0,0,1,1.057-.146c.992,0,1.545.179,1.545.764,0,.423-.325.667-.716.976A4.086,4.086,0,0,0,3.409-8.3c0,.276.1.407.374.407H5.312c.276,0,.374-.114.374-.374,0-.569.407-.927,1.138-1.675a3.616,3.616,0,0,0,1.447-2.732C8.272-14.445,6.874-15.356,4.694-15.356Zm1.041,8.8a.673.673,0,0,0-.7-.211l-1.171.2a.659.659,0,0,0-.6.5,3.261,3.261,0,0,0-.1.748c0,1.025.488,1.5,1.4,1.5a2.657,2.657,0,0,0,1.057-.211A1.691,1.691,0,0,0,6.093-5.3,2.305,2.305,0,0,0,5.735-6.557Z" transform="translate(948.141 137.814)" fill="#fff"/>
            </g>
        </svg>

    )
}

export default QuestionIcon;