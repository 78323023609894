import React from "react";

function ShareIcon({width="21",height="23"}) {
  return (
    <svg
      id="Grupo_28319"
      data-name="Grupo 28319"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21 23"
    >
      <g
        id="Elipse_12"
        data-name="Elipse 12"
        transform="translate(21 23) rotate(180)"
        fill="#002554"
        stroke="#002554"
        strokeWidth="1"
      >
        <circle cx="3.5" cy="3.5" r="3.5" stroke="none" />
        <circle cx="3.5" cy="3.5" r="3" fill="none" />
      </g>
      <g
        id="Elipse_14"
        data-name="Elipse 14"
        transform="translate(7 15) rotate(180)"
        fill="#002554"
        stroke="#002554"
        strokeWidth="1"
      >
        <circle cx="3.5" cy="3.5" r="3.5" stroke="none" />
        <circle cx="3.5" cy="3.5" r="3" fill="none" />
      </g>
      <g
        id="Elipse_13"
        data-name="Elipse 13"
        transform="translate(21 7) rotate(180)"
        fill="#002554"
        stroke="#002554"
        strokeWidth="1"
      >
        <circle cx="3.5" cy="3.5" r="3.5" stroke="none" />
        <circle cx="3.5" cy="3.5" r="3" fill="none" />
      </g>
      <path
        id="Trazado_164"
        data-name="Trazado 164"
        d="M9.023,0,0,5.34"
        transform="translate(14.991 9.789) rotate(180)"
        fill="#fff"
        stroke="#002554"
        strokeWidth="2"
      />
      <path
        id="Trazado_165"
        data-name="Trazado 165"
        d="M9.431,5.7,0,0"
        transform="translate(15.398 19.074) rotate(180)"
        fill="#fff"
        stroke="#002554"
        strokeWidth="2"
      />
    </svg>
  );
}

export default ShareIcon;
