import fetch from 'node-fetch';
import {
  ClientBuilder,

  // Import middlewares
  type AuthMiddlewareOptions, // Required for auth
  type HttpMiddlewareOptions, // Required for sending HTTP requests
} from '@commercetools/sdk-client-v2';

import CONST_PROJECT from "@/shared/consts/CONST_PROJECT.json"

const scopes = [CONST_PROJECT.SCOPES];
const projectKey=CONST_PROJECT.PROJECT_KEY

// Configure authMiddlewareOptions
const authMiddlewareOptions: AuthMiddlewareOptions = {
  host: CONST_PROJECT.AUTH_URL,
  projectKey,
  credentials: {
    clientId: CONST_PROJECT.CLIENT_ID,
    clientSecret: CONST_PROJECT.CLIENT_SECRET,
  },
  scopes,
  fetch,
};

// Configure httpMiddlewareOptions
const httpMiddlewareOptions: HttpMiddlewareOptions = {
  host: CONST_PROJECT.HOST,
  fetch,
};

// Export the ClientBuilder
export const ctpClient = new ClientBuilder()
  .withProjectKey(projectKey) // .withProjectKey() is not required if the projectKey is included in authMiddlewareOptions
  .withClientCredentialsFlow(authMiddlewareOptions)
  .withHttpMiddleware(httpMiddlewareOptions)
  .withLoggerMiddleware() // Include middleware for logging
  .build();


  console.log(CONST_PROJECT,"KKKKKKKKKKKKK")