import React, { CSSProperties } from 'react';

const PrintIcon = ( {style={}}:{style?:CSSProperties}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="17.1" viewBox="0 0 19 17.1" style={style}>
      <path id="print_FILL1_wght400_GRAD0_opsz24" d="M95.2-836.2H83.8V-840H95.2Zm0,5.225a.919.919,0,0,0,.677-.273.92.92,0,0,0,.273-.677.919.919,0,0,0-.273-.677.919.919,0,0,0-.677-.273.919.919,0,0,0-.677.273.919.919,0,0,0-.273.677.92.92,0,0,0,.273.677A.919.919,0,0,0,95.2-830.975ZM93.3-824.8v-3.8H85.7v3.8Zm1.9,1.9H83.8v-3.8H80v-5.7a2.737,2.737,0,0,1,.831-2.031,2.769,2.769,0,0,1,2.019-.819h13.3a2.758,2.758,0,0,1,2.031.819A2.758,2.758,0,0,1,99-832.4v5.7H95.2Z" transform="translate(-80 840)" fill="#165eeb"/>
    </svg>
  );
};

export default PrintIcon;
