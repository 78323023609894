import React from 'react';

const CheckIcon = ({ fill = '#28a745' }: { fill?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
      <g id="exito" transform="translate(-2 -2)">
        <path
          id="Trazado_182674"
          data-name="Trazado 182674"
          d="M32,2A30,30,0,1,0,62,32,30.011,30.011,0,0,0,32,2ZM26,47,11,32l4.23-4.23L26,38.51,48.77,15.74,53,20Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default CheckIcon;
