import React from 'react';

interface Icon {
  color?: string
}


const ConfiguracionIcon = ({color}: Icon) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.55" height="14.549" viewBox="0 0 14.55 14.549">
      <path id="configuracion" d="M3624.881-389.677h-1.212a.911.911,0,0,1-.91-.91v-1.128a5.415,5.415,0,0,1-1.115-.463l-.8.8a.91.91,0,0,1-.643.258.913.913,0,0,1-.644-.258l-.858-.857a.9.9,0,0,1-.266-.643.9.9,0,0,1,.266-.643l.8-.8a5.4,5.4,0,0,1-.462-1.115h-1.128a.911.911,0,0,1-.909-.91v-1.212a.9.9,0,0,1,.266-.643.9.9,0,0,1,.643-.267h1.129a5.364,5.364,0,0,1,.462-1.115l-.8-.8a.9.9,0,0,1-.266-.643.9.9,0,0,1,.266-.643l.857-.858a.912.912,0,0,1,.643-.258.915.915,0,0,1,.644.258l.8.8a5.449,5.449,0,0,1,1.115-.462v-1.128a.911.911,0,0,1,.91-.91h1.212a.911.911,0,0,1,.909.91v1.128a5.431,5.431,0,0,1,1.115.462l.8-.8a.912.912,0,0,1,.643-.258.912.912,0,0,1,.643.258l.857.857a.9.9,0,0,1,.266.643.9.9,0,0,1-.266.643l-.8.8a5.429,5.429,0,0,1,.463,1.116h1.128a.911.911,0,0,1,.91.91v1.212a.907.907,0,0,1-.266.643.907.907,0,0,1-.644.266h-1.129a5.439,5.439,0,0,1-.462,1.116l.8.8a.9.9,0,0,1,.266.643.9.9,0,0,1-.266.643l-.857.858a.912.912,0,0,1-.643.258.912.912,0,0,1-.643-.258l-.8-.8a5.427,5.427,0,0,1-1.116.463v1.128A.911.911,0,0,1,3624.881-389.677Zm-.606-9.7a2.427,2.427,0,0,0-2.425,2.424,2.428,2.428,0,0,0,2.425,2.425,2.428,2.428,0,0,0,2.424-2.425A2.427,2.427,0,0,0,3624.274-399.376Z" transform="translate(-3617 404.225)" fill={color ? color: '#165EEB'}/>
    </svg>
  );
};

export default ConfiguracionIcon;
