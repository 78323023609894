import React from 'react';

const B2CInProgress = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      style={{ display: 'inline' }}
    >
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectángulo_8908" data-name="Rectángulo 8908" width="22.727" height="22.727" fill="#07367e" />
        </clipPath>
      </defs>
      <g id="Grupo_32882" data-name="Grupo 32882" transform="translate(-20 -460)">
        <rect
          id="Rectángulo_9819"
          data-name="Rectángulo 9819"
          width="25"
          height="25"
          transform="translate(20 460)"
          fill="none"
        />
        <g id="Grupo_32787" data-name="Grupo 32787" transform="translate(21.136 461.136)">
          <g id="Grupo_29763" data-name="Grupo 29763" transform="translate(0)" clip-path="url(#clip-path)">
            <path
              id="Trazado_183038"
              data-name="Trazado 183038"
              d="M11.364,0A11.364,11.364,0,1,0,22.727,11.364,11.363,11.363,0,0,0,11.364,0m3.485,10.017v1.825H10.286V5.455h1.825v4.562Zm-7.8-5.085a7.294,7.294,0,0,0,8.315,11.985l-1.426-1.426H19l-1.161,4.562-1.161-1.825a9.124,9.124,0,1,1,3.65-7.3H18.5a7.294,7.294,0,0,0-11.452-6"
              transform="translate(0 0)"
              fill="#07367e"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default B2CInProgress;
