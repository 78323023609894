import React from "react";

function AddIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 14.048 14.048"
    >
      <g id="Grupo_27583" data-name="Grupo 27583" transform="translate(0)">
        <path
          id="Trazado_148"
          data-name="Trazado 148"
          d="M3521,597v14.048"
          transform="translate(-3513.976 -597)"
          fill="none"
          stroke="#002554"
          strokeWidth="2"
        />
        <path
          id="Trazado_149"
          data-name="Trazado 149"
          d="M0,0V14.048"
          transform="translate(14.048 7.024) rotate(90)"
          fill="none"
          stroke="#002554"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export default AddIcon;
