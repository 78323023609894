import React from 'react';

const B2CEmptyStateOrdersIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="52.229" height="55" viewBox="0 0 52.229 55" style={{display:"inline-block"}}>
      <path
        id="nuevas_solicitudes_icon"
        data-name="nuevas solicitudes icon"
        d="M12.991,2,2,2.044l.027,5.5,5.461-.027V35a8.291,8.291,0,0,0,8.248,8.248H54.233v-5.5H15.74A2.706,2.706,0,0,1,12.991,35Zm11,2.76v11H51.484v-11Zm-5.5,16.5v11H48.734v-11ZM18.49,46a5.5,5.5,0,1,0,5.5,5.5A5.5,5.5,0,0,0,18.49,46Zm27.495,0a5.5,5.5,0,1,0,5.5,5.5A5.5,5.5,0,0,0,45.985,46Z"
        transform="translate(-2.004 -1.996)"
        fill="#828384"
      />
    </svg>
  );
};

export default B2CEmptyStateOrdersIcon;
