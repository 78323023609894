import React from 'react';

const CarnetIcon = () => {
  return (
    <svg id="carnet" xmlns="http://www.w3.org/2000/svg" width="58" height="35" viewBox="0 0 58 35">
      <rect id="Rectángulo_9525" data-name="Rectángulo 9525" width="58" height="35" rx="2" fill="#e50019" />
      <path
        id="Trazado_184096"
        data-name="Trazado 184096"
        d="M31.368,28.54c7.114,0,13.009,2.72,14.188,6.292a4.2,4.2,0,0,0,.228-1.308c0-4.2-6.454-7.6-14.416-7.6s-14.417,3.4-14.417,7.6a4.173,4.173,0,0,0,.228,1.308c1.179-3.572,7.075-6.292,14.189-6.292m0-4.523c7.114,0,13.009,2.719,14.188,6.292A4.2,4.2,0,0,0,45.784,29c0-4.2-6.454-7.6-14.416-7.6S16.952,24.8,16.952,29a4.178,4.178,0,0,0,.228,1.309c1.179-3.572,7.075-6.292,14.189-6.292m5.923,18.2-2.962-3.771H32.919v5.623h1.345v-3.69l2.919,3.69h1.453V38.444H37.291Zm-22.176.69a1.7,1.7,0,0,1-.994.288,1.8,1.8,0,0,1-1.274-.453,1.994,1.994,0,0,1-.49-1.52,1.878,1.878,0,0,1,.5-1.455,1.857,1.857,0,0,1,1.295-.449,1.808,1.808,0,0,1,.98.253,1.189,1.189,0,0,1,.531.69l1.439-.267a2.151,2.151,0,0,0-.736-1.035,3.519,3.519,0,0,0-2.142-.608,3.677,3.677,0,0,0-2.438.776,2.686,2.686,0,0,0-.926,2.179,2.572,2.572,0,0,0,.923,2.092,3.571,3.571,0,0,0,2.355.765,3.68,3.68,0,0,0,1.912-.451,2.487,2.487,0,0,0,1.077-1.374L15.714,42a1.6,1.6,0,0,1-.6.907m31.274-4.461v.951h2.139v4.672h1.455V39.394h2.123v-.951Zm-4.747,3.145h3.609v-.951H41.642V39.394h3.876v-.951H40.187v5.623H45.66v-.951H41.642Zm-11.224.47a3.413,3.413,0,0,0-.776-.471,2.579,2.579,0,0,0,1.422-.52,1.322,1.322,0,0,0,.475-1.046,1.37,1.37,0,0,0-.315-.887,1.632,1.632,0,0,0-.837-.54,6.608,6.608,0,0,0-1.677-.152H25.65v5.623h1.455V41.723h.3a3.075,3.075,0,0,1,.732.062,1.172,1.172,0,0,1,.438.233,8.489,8.489,0,0,1,.768.821l1.044,1.227h1.739l-.879-1.095a6.633,6.633,0,0,0-.825-.912M28.18,40.826H27.105V39.394h1.133c.588,0,.942.007,1.06.02a.987.987,0,0,1,.547.221.592.592,0,0,1,.194.46.622.622,0,0,1-.148.423.792.792,0,0,1-.407.239,7.042,7.042,0,0,1-1.3.069m-7.734-2.383-2.8,5.623h1.542l.6-1.275h2.88l.627,1.275h1.581l-2.88-5.623Zm-.22,3.4.975-2.083.991,2.083Z"
        transform="translate(-2.48 -15.345)"
        fill="#fff"
      />
    </svg>
  );
};

export default CarnetIcon;
