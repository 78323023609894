import React from 'react';

const PayProviderIcon = () => {
  return (
    <svg
      id="Grupo_31672"
      data-name="Grupo 31672"
      xmlns="http://www.w3.org/2000/svg"
    //   xmlns:xlink="http://www.w3.org/1999/xlink"
      width="37.411"
      height="25.44"
      viewBox="0 0 37.411 25.44"
    >
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectángulo_9494" data-name="Rectángulo 9494" width="37.411" height="25.44" fill="#828384" />
        </clipPath>
      </defs>
      <g id="Grupo_31674" data-name="Grupo 31674" transform="translate(0 0)">
        <g id="Grupo_31673" data-name="Grupo 31673" clip-path="url(#clip-path)">
          <path
            id="Trazado_183996"
            data-name="Trazado 183996"
            d="M8.832,15.719,5.869,18.682,4.653,17.465a.748.748,0,0,0-1.058,1.058L5.34,20.27a.758.758,0,0,0,1.058,0l3.492-3.492a.748.748,0,0,0-1.058-1.058"
            transform="translate(-0.008 -0.037)"
            fill="#828384"
          />
          <path
            id="Trazado_183997"
            data-name="Trazado 183997"
            d="M36.663,0H5.238a.748.748,0,0,0-.748.748v9.687L.512,11.761A.748.748,0,0,0,0,12.47v4.554a8.639,8.639,0,0,0,6.552,8.393.758.758,0,0,0,.363,0,8.634,8.634,0,0,0,6.2-5.964H36.663a.749.749,0,0,0,.748-.748V.748A.749.749,0,0,0,36.663,0M11.972,17.025a7.144,7.144,0,0,1-5.238,6.894A7.144,7.144,0,0,1,1.5,17.025V13.01l5.238-1.746,5.238,1.746Zm.985-5.264L8.979,10.435V6.381A2.753,2.753,0,0,0,10.87,4.489h7.071a8.04,8.04,0,0,0-1.73,5.238,8.04,8.04,0,0,0,1.73,5.238H13.468V12.47a.748.748,0,0,0-.512-.709M24.193,9.727c0,2.839-1.484,5.238-3.242,5.238s-3.242-2.4-3.242-5.238,1.484-5.238,3.242-5.238,3.242,2.4,3.242,5.238m-.233-5.238H31.03a2.753,2.753,0,0,0,1.892,1.892v6.692a2.753,2.753,0,0,0-1.892,1.892H23.959a8.04,8.04,0,0,0,1.73-5.238,8.04,8.04,0,0,0-1.73-5.238M35.915,17.957h-22.5a8.736,8.736,0,0,0,.056-.933v-.564H31.675a.748.748,0,0,0,.748-.748,1.249,1.249,0,0,1,1.247-1.247.749.749,0,0,0,.748-.748V5.736a.749.749,0,0,0-.748-.748,1.249,1.249,0,0,1-1.247-1.247.748.748,0,0,0-.748-.748H10.226a.749.749,0,0,0-.748.748A1.249,1.249,0,0,1,8.23,4.988a.748.748,0,0,0-.748.748v4.2L6.97,9.766a.738.738,0,0,0-.473,0l-.512.171V1.5H35.915Z"
            fill="#828384"
          />
          <path
            id="Trazado_183998"
            data-name="Trazado 183998"
            d="M21,7a.749.749,0,0,0-.748.748v3.991a.748.748,0,1,0,1.5,0V7.748A.748.748,0,0,0,21,7"
            transform="translate(-0.048 -0.017)"
            fill="#828384"
          />
          <path
            id="Trazado_183999"
            data-name="Trazado 183999"
            d="M28.248,10.5h2.494a.748.748,0,1,0,0-1.5H28.248a.748.748,0,1,0,0,1.5"
            transform="translate(-0.065 -0.021)"
            fill="#828384"
          />
          <path
            id="Trazado_184000"
            data-name="Trazado 184000"
            d="M14.491,9.748A.749.749,0,0,0,13.742,9H11.248a.748.748,0,1,0,0,1.5h2.494a.749.749,0,0,0,.748-.748"
            transform="translate(-0.025 -0.021)"
            fill="#828384"
          />
        </g>
      </g>
    </svg>
  );
};

export default PayProviderIcon;
