import React from 'react';

const EmptyStateTruck = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="69" height="48" viewBox="0 0 69 48">
      <path
        id="ordenes_e_comm_empty"
        data-name="ordenes e comm empty"
        d="M0,5v6H21v6H3v6H15v6H3V44H9a9,9,0,1,0,18,0H45a9,9,0,1,0,18,0h6V29L60.826,12.658A2.988,2.988,0,0,0,58.148,11H48a6,6,0,0,0-6-6ZM48,16.25h8.754L63.129,29H48Zm-30,24A3.75,3.75,0,1,1,14.25,44,3.755,3.755,0,0,1,18,40.25Zm36,0A3.75,3.75,0,1,1,50.25,44,3.755,3.755,0,0,1,54,40.25Z"
        transform="translate(0 -5)"
        fill="#828384"
      />
    </svg>
  );
};

export default EmptyStateTruck;
